import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import LanguageContext from 'language-context';
import { useContext } from 'react';

export default function PdfPreview({ orders, onClose }) {
  const tc = useContext(LanguageContext);
  return (
    <>
      <Popup
        size="xl"
        close={() => {
          onClose();
        }}
      >
        <div className="genericPopupContentWrapper">
          <div className="genericPopupContentWrapper__genericPopupContent">
            <div className="genericPopupContentWrapper__genericPopupContent__header">
              <WidgetHeader
                headline={tc.previewResult}
                headlineSub={tc.namesAreExamples}
              />
            </div>
            <div className="genericPopupContentWrapper__genericPopupContent__content">
              <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <img
                  src={orders.mailingsPdfPreview.blob}
                  alt="Förhandsgranskning av pdf"
                />
              </div>
            </div>
            <div className="genericPopupContentWrapper__genericPopupContent__footer">
              <WidgetFooter
                buttonTwoFunc={() => {
                  onClose();
                }}
                buttonTwoText={tc.closePreview}
              />
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
