import React, { useContext, useEffect, useState } from 'react';
import WidgetHeader from 'components/widget_header';
import { connect } from 'react-redux';
import Loading from 'components/loading';
import LanguageContext from 'language-context';
import { NavLink } from 'react-router-dom';

/**
 * Company additional information.
 *
 * @param companyInfo - Information about the company.
 * @param isMinimized - A boolean indicating whether the component is minimized or not.
 * @param onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const CompanyAdditionalInformation = ({
  companyInfo,
  deals,
  isMinimized,
  onMinimizeChange,
  isCompanyPage,
}) => {
  const [minimize, setMinimize] = useState(true);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    setMinimize(isMinimized);
  }, [isMinimized]);

  return (
    <div className="companyWrapper__info">
      <WidgetHeader
        dashboardItems={
          isCompanyPage
            ? null
            : [
                {
                  disabled: !minimize,
                  icon: 'maximize',
                  label: tc.maximize,
                  onClick: () => {
                    setMinimize(false);
                  },
                },
                {
                  disabled: minimize,
                  icon: 'minimize',
                  label: tc.minimize,
                  onClick: () => {
                    setMinimize(true);
                    if (onMinimizeChange) {
                      onMinimizeChange(true);
                    }
                  },
                },
              ]
        }
        headline={tc.additionalInformation}
        headlineSub={tc.aboutCompany}
      />
      <div
        className={
          minimize
            ? 'companyInfoWrapper__companyInfo__content minimize'
            : 'companyInfoWrapper__companyInfo__content'
        }
      >
        <div className="companyInfoWrapper__companyInfo__content__itemsWrapper">
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.netSales + ' (TKR)'}
                </p>
              </div>
              <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                {companyInfo ? (
                  <p>
                    {companyInfo.netoms_AB_X !== undefined ? (
                      new Intl.NumberFormat('sv-SE', {
                        minimumFractionDigits: 0,
                      }).format(companyInfo.netoms_AB_X)
                    ) : (
                      <>
                        <span>-</span>
                      </>
                    )}
                  </p>
                ) : (
                  <>
                    <Loading small={true} />
                  </>
                )}
              </div>
            </div>

            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.noEmployees}
                </p>
              </div>

              {companyInfo ? (
                <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                  <p>
                    {companyInfo.antanst_AB_X
                      ? `${companyInfo.antanst_AB_X} ${tc.aPiece.toLowerCase()}`
                      : '-'}
                  </p>
                </div>
              ) : (
                <Loading small={true} />
              )}
            </div>

            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.cashLiquidity}
                </p>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                {companyInfo ? (
                  <p>
                    {companyInfo.kasslikvid_NT_AB_X !== undefined &&
                    companyInfo.kasslikvid_NT_AB_X !== null
                      ? `${companyInfo.kasslikvid_NT_AB_X}%`
                      : '-'}
                  </p>
                ) : (
                  <>
                    <Loading small={true} />
                  </>
                )}
              </div>
            </div>

            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.solidity}
                </p>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                {companyInfo ? (
                  <p>
                    {companyInfo.solid_NT_AB_X !== undefined &&
                    companyInfo.solid_NT_AB_X !== null
                      ? `${companyInfo.solid_NT_AB_X}%`
                      : '-'}
                  </p>
                ) : (
                  <>
                    <Loading small={true} />
                  </>
                )}
              </div>
            </div>

            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.resefinintokost}
                </p>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                {companyInfo ? (
                  <p>
                    {companyInfo.resefinintokost_AB_X !== undefined &&
                    companyInfo.resefinintokost_AB_X !== null
                      ? new Intl.NumberFormat('sv-SE', {
                          minimumFractionDigits: 0,
                        }).format(companyInfo.resefinintokost_AB_X)
                      : '-'}
                  </p>
                ) : (
                  <>
                    <Loading small={true} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.lineOfBusiness}
                </p>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer__custom__col">
                {companyInfo ? (
                  companyInfo.abv_hgrupp || companyInfo.abv_ugrupp ? (
                    <>
                      {companyInfo.abv_hgrupp && (
                        <p>{companyInfo.abv_hgrupp}</p>
                      )}
                      {companyInfo.abv_ugrupp && (
                        <p>{companyInfo.abv_ugrupp}</p>
                      )}
                    </>
                  ) : (
                    <span>-</span>
                  )
                ) : (
                  <Loading small={true} />
                )}
              </div>
            </div>

            <div className="companyInfoWrapper__companyInfo__content__flexContainer">
              <div className="companyInfoWrapper__companyInfo__content__widthContainer">
                <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
                  {tc.companySignatory}
                </p>
              </div>

              <div className="companyInfoWrapper__companyInfo__content__flexContainer__flex">
                {companyInfo ? (
                  companyInfo.mgmt && companyInfo.mgmt_type ? (
                    <>
                      <span>{`${companyInfo.mgmt} - ${companyInfo.mgmt_type}`}</span>
                    </>
                  ) : (
                    <span>-</span>
                  )
                ) : (
                  <Loading small={true} />
                )}
              </div>
            </div>
          </div>
          <div className="companyInfoWrapper__companyInfo__content__itemsWrapper__item">
            <p className="companyInfoWrapper__companyInfo__content__itemsWrapper__item__companyInfoLabel">
              {tc.partOfDeals}
            </p>

            {deals ? (
              deals.length > 0 ? (
                deals.map((d, i) => (
                  <span className="dealLink" key={d.id}>
                    <NavLink exact to={`/affar/${d.id}`}>
                      {d.name || tc.deal}
                    </NavLink>
                    {i !== deals.length - 1 && <span>{', '}</span>}
                  </span>
                ))
              ) : (
                <p>
                  <span className="italic">{tc.noDeals}</span>
                </p>
              )
            ) : (
              <Loading small={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companyInfo: state.company.info,
    deals: state.company.deals,
  };
};

export default connect(mapStateToProps)(CompanyAdditionalInformation);
