import LanguageContext from 'language-context';
import { useContext, useState } from 'react';
import { getOptions } from '../lib.ts';
import { getUserPdfs, uploadUserPdf } from 'store/pdf/tasks';
import styles from './upload_pdf_file.module.css';
import ActionButton from 'components/action_button/action_button.js';

export default function UploadPdfFile({ handleUploadingChange }) {
  const [file, setFile] = useState(null);
  const [settings, setSettings] = useState({
    Typ: null,
    ADR: null,
    fileName: null,
  });
  const [isUploading, setIsUploading] = useState(false);
  const tc = useContext(LanguageContext);

  const handleUpload = async () => {
    setIsUploading(true);
    await uploadUserPdf({
      pdf: file,
      type: 'mailings',
      name: settings.fileName,
      ADR: settings.ADR,
      Typ: settings.Typ,
    });
    await getUserPdfs({ type: 'mailings' });
    setIsUploading(false);
    setFile(null);
    handleUploadingChange(false);
  };

  const handleCancel = () => {
    setFile(null);
    handleUploadingChange(false);
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value) {
      setSettings((prev) => {
        return { ...prev, ADR: value, Typ: options[value].Typ };
      });
    } else {
      setSettings({ Typ: null, ADR: null, filename: null });
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (!value) {
      return setSettings((prev) => ({ ...prev, fileName: null }));
    } else setSettings((prev) => ({ ...prev, fileName: value }));
  };

  if (!file)
    return (
      <form className={styles.container}>
        <label>
          <p>{tc.uploadFileToPDFArchive}</p>
          <input
            className={styles.uploadPdfInput}
            type="file"
            accept=".pdf"
            onChange={(e) => {
              const newFile = e.target?.files?.[0];
              if (newFile) {
                setFile(newFile);
                setSettings((prev) => ({ ...prev, fileName: newFile.name }));
                handleUploadingChange(true);
              } else {
                setFile(null);
                handleUploadingChange(false);
              }
            }}
          />
        </label>
      </form>
    );

  const options = getOptions();
  return (
    <div className={styles.container}>
      <p className={styles.filename}>{file.name}</p>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <label>
            Välj en ADR-typ för din PDF-fil.
            <select value={settings.ADR || ''} onChange={handleSelect}>
              <option value="">Välj en ADR-typ</option>
              {Object.keys(options).map((option) => (
                <option key={option} value={options[option].ADR}>
                  {options[option].label}{' '}
                  {['Brev'].includes(option) ? null : `(ADR${option})`}
                </option>
              ))}
            </select>
          </label>

          <label>
            Välj namn på din uppladdade PDF-fil.
            <input
              type="text"
              value={settings.fileName || ''}
              placeholder="Vykort brevutskick 2025"
              onChange={handleNameChange}
            />
          </label>

          <div className={styles.buttonContainer}>
            <ActionButton
              class={styles.upload}
              label={tc.upload}
              type="highlight"
              disabled={isUploading || !file}
              onClick={handleUpload}
            />
            <ActionButton
              class={styles.cancel}
              label={tc.cancel}
              type="regular"
              onClick={handleCancel}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
