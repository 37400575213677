import styles from './order_step.module.css';

export default function OrderStep({ label, children }) {
  return (
    <details className={styles.details} open={true}>
      <summary>{label}</summary>
      <div className={styles.orderStepHolder}>{children}</div>
    </details>
  );
}
