import Loading from 'components/loading';

export default function PriceDisplay({ name, mailings, loading, error }) {
  return (
    <div style={{ marginTop: '24px', paddingBottom: '24px' }}>
      {loading ? (
        <Loading small={true} />
      ) : (
        <div style={{ height: '12px' }}></div>
      )}
      {loading ? (
        <p>Beräknar kostnad för namn...</p>
      ) : (
        <p>Kostnad namn {name} kr</p>
      )}
      {loading ? (
        <p>Beräknar kostnad för brev + porto...</p>
      ) : (
        <p>Kostnad brev + porto {mailings} kr</p>
      )}
      <hr />
      <p style={{ fontSize: '16px' }}>
        Summa {loading ? ' - ' : mailings + name} kr
      </p>
    </div>
  );
}
