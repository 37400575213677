import { miscHelper } from 'helpers';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserPdfs } from 'store/pdf/tasks';
import PdfArchiveManageFilesNew from 'components/pdf_archive_manage_files/archive/pdf_archive_manage_files_new';
import {
  checkIfFieldDisabled,
  getDefaultSettingsFromADR,
} from 'components/pdf_archive_manage_files/lib';
import {
  createMailingsOrderWithParams,
  createMailingsPreviewNew,
} from 'store/orders/tasks';
import Loading from 'components/loading';
import LanguageContext from 'language-context';
import Info from 'components/info';
import { fetchRequest } from 'helpers/request_helper';
import { showFlashMessage } from 'store/flash_messages/tasks';
import PriceDisplay from './price_display';
import OrderStep from './order_step';
import PdfPreview from './pdf_preview';
import styles from './order_mailings.module.css';
import ActionButton from 'components/action_button';

function OrderMailings({ orders, pdf, list, mailingsVehicleError }) {
  const companies = list?.prospectInformation?.companies ?? 0;
  const nameIsFresh =
    list?.orderHistory?.name?.isDelivered &&
    list?.orderHistory?.name?.isAvailable;
  const orderIsPending = miscHelper.hasPendingOrder(list);
  const privatePersons = list?.prospectInformation?.privatePersons ?? 0;
  const amount = companies + privatePersons;
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [orderStep, setOrderStep] = useState(1);
  const [mailingsSettings, setMailingsSettings] = useState({
    mailingsType: null,
    Typ: null,
    ADR: null,
    postage: 'A',
    color: true,
    simplexDuplex: 'D',
  });
  const [previewStatus, setPreviewStatus] = useState({
    show: false,
    loading: false,
    completed: false,
  });
  const [price, setPrice] = useState({
    name: null,
    mailings: null,
    error: null,
    loading: false,
  });
  const [isCreating, setIsCreating] = useState(false);
  const tc = useContext(LanguageContext);

  const selectedPdf = pdf?.userPdfs?.find((pdf) => pdf.id === selectedPdfId);
  const previewButtonDisabled = orderStep < 4 || previewStatus.loading;
  const createOrderButtonDisabled =
    orderStep < 6 ||
    !previewStatus.completed ||
    price.error ||
    (mailingsVehicleError && mailingsSettings.mailingsType === 'car') ||
    isCreating;
  useEffect(() => {
    getUserPdfs({ type: 'mailings' });
  }, []);
  useEffect(() => {
    if (orderStep < 4) return;
    const allKeysTruthy = Object.keys(mailingsSettings)
      .filter((key) => !['color'].includes(key))
      .every((key) => mailingsSettings[key]);
    if (!allKeysTruthy) return;
    async function calculatePrice() {
      try {
        setPrice((price) => {
          return { ...price, loading: true };
        });
        const price = await fetchRequest('/mailings/calculatePrice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            listId: list.id,
            ...mailingsSettings,
            nameIsFresh,
          }),
        });
        if (price.data.error) {
          setPrice((price) => {
            return {
              ...price,
              mailings: null,
              name: null,
              error: true,
              loading: false,
            };
          });
          showFlashMessage(
            'Något verkar ha gått fel vid beräkning av pris för detta utskick. Om problemet kvarstår, var god kontakta support.',
            'info'
          );
        } else {
          setPrice({
            name: nameIsFresh ? 0 : price.data.nameAmount,
            mailings: price.data.mailingsAmount,
            error: null,
            loading: false,
          });
        }
      } catch (err) {
        console.error('Err: ', err);
        showFlashMessage(
          'Något verkar ha gått fel vid hämtning av pris för beställning. Om problemet kvarstår, var god kontakta support.',
          'info'
        );
        setPrice({ name: null, mailings: null, error: true, loading: false });
      }
    }
    calculatePrice();
  }, [mailingsSettings, amount, nameIsFresh, list.id, orderStep]);

  async function handlePreview() {
    if (previewStatus.loading) return;
    setPreviewStatus((prev) => {
      return { ...prev, loading: true };
    });
    await createMailingsPreviewNew({
      pdfId: selectedPdfId,
      postage: mailingsSettings.postage,
      simplexDuplex: mailingsSettings.simplexDuplex,
      type: mailingsSettings.Typ,
      ADR: mailingsSettings.ADR,
      color: mailingsSettings.color,
    });
    setPreviewStatus((prev) => {
      return { ...prev, loading: false, show: true, completed: false };
    });
  }
  function toggleColor() {
    setMailingsSettings((settings) => {
      return { ...settings, color: !settings.color };
    });
  }
  function toggleSimplexDuplex() {
    setMailingsSettings((settings) => {
      const next = settings.simplexDuplex === 'S' ? 'D' : 'S';
      return {
        ...settings,
        simplexDuplex: next,
      };
    });
  }

  if (orderIsPending)
    return (
      <>
        <Info>
          <p>Den här listan verkar ha en pågående beställning just nu.</p>
          <p>Därför kan en beställning inte läggas tills vidare.</p>
        </Info>
      </>
    );
  return (
    <div className={styles.container}>
      <OrderStep label={`1. ${tc.selectFile}`}>
        <p className={styles.stepInstruction}>
          En PDF-fil som används för utskick behöver ha laddats upp till ditt
          PDF-bibliotek. När uppladdning sker skickas filen till Bilprospekts
          support för validering mot tryckeriets krav. När filen är godkänd kan
          den användas för ett utskick.
        </p>
        <PdfArchiveManageFilesNew
          onSelect={(id) => {
            if (selectedPdfId === id) return;
            setSelectedPdfId(id);
            setOrderStep((step) => {
              if (step > 2) return step;
              else return 2;
            });
            setMailingsSettings((prev) => {
              const currentPdf = pdf.userPdfs.find((pdf) => pdf.id === id);
              return {
                ...prev,
                Typ: currentPdf.Typ,
                ADR: currentPdf.ADR,
                ...getDefaultSettingsFromADR(
                  pdf.userPdfs.find((pdf) => pdf.id === id).ADR
                ),
              };
            });
            setPreviewStatus((prev) => {
              return { ...prev, completed: false };
            });
          }}
          selectedIdForMailing={selectedPdfId}
        />
      </OrderStep>

      <OrderStep label={`2. ${tc.chooseMailingsMode}`}>
        <p className={styles.stepInstruction}>
          {tc.carMailingsTypeExplanation}
        </p>
        <label className={styles.radioSelector}>
          {tc.everyUser}
          <input
            className="pdf-selector-mailings"
            name="user"
            type="radio"
            value={'user'}
            disabled={orderStep < 2 || price.loading}
            checked={mailingsSettings.mailingsType === 'user'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, mailingsType: 'user' };
                });
              }
              if (orderStep === 2) {
                setOrderStep(4);
              }
            }}
          />
        </label>
        <label className={styles.radioSelector}>
          {tc.everyVehicle}
          <input
            className="pdf-selector-mailings"
            name="car"
            type="radio"
            value={'car'}
            disabled={orderStep < 2 || !list.meta.criterias || price.loading}
            checked={mailingsSettings.mailingsType === 'car'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, mailingsType: 'car' };
                });
              }

              if (orderStep === 2) {
                setOrderStep(4);
              }
            }}
          />
        </label>
      </OrderStep>

      <OrderStep label={`3. ${tc.customizeOrder}`}>
        <p className={styles.stepInstruction}>
          Här kan du välja att anpassa ditt utskick. Om den PDF-fil du valt är
          ett vykort så är vissa alternativ inte tillgängliga.
        </p>
        <label
          htmlFor="postage-mailings-A"
          className={[styles.radioSelector]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          <span>{tc.postageA}</span>
          <input
            className="pdf-selector-mailings"
            name="postageA"
            id="postage-mailings-A"
            type="radio"
            value={'A'}
            disabled={
              orderStep < 3 ||
              checkIfFieldDisabled(selectedPdf.Typ, 'postage') ||
              price.loading
            }
            checked={mailingsSettings.postage === 'A'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, postage: 'A' };
                });
              }
            }}
          />
        </label>

        <label
          htmlFor="postage-mailings-B"
          className={[styles.radioSelector]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          <span>{tc.postageB}</span>
          <input
            className="pdf-selector-mailings"
            name="postage-mailings-B"
            id="postage-mailings-B"
            type="radio"
            disabled={
              orderStep < 3 ||
              checkIfFieldDisabled(selectedPdf.Typ, 'postage') ||
              price.loading
            }
            value={'B'}
            checked={mailingsSettings.postage === 'B'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, postage: 'B' };
                });
              }
            }}
          />
        </label>
        <label
          className={[styles.checkbox]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'color')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          <span>{tc.color}</span>
          <input
            className="pdf-selector-mailings"
            type="checkbox"
            checked={mailingsSettings.color === true}
            disabled={
              orderStep < 3 ||
              checkIfFieldDisabled(selectedPdf.Typ, 'color') ||
              price.loading
            }
            onChange={toggleColor}
          />
        </label>
        <label
          className={[styles.checkbox]
            .concat(
              orderStep < 3 ||
                checkIfFieldDisabled(selectedPdf.Typ, 'simplexDuplex')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          <span>{tc.doubleSided}</span>
          <input
            className="pdf-selector-mailings"
            type="checkbox"
            checked={mailingsSettings.simplexDuplex === 'D'}
            disabled={
              orderStep < 3 ||
              checkIfFieldDisabled(selectedPdf.Typ, 'simplexDuplex') ||
              price.loading
            }
            onChange={toggleSimplexDuplex}
          />
        </label>
      </OrderStep>

      <OrderStep label={`4. ${tc.checkResult}`}>
        <p className={styles.stepInstruction}>
          Här förhandsgranskar du filen du valde i steg 1.
        </p>

        {previewStatus.loading ? (
          <>
            <Loading />
            <p>Laddar förhandgranskning av PDF-fil. Det här kan ta en stund.</p>
          </>
        ) : null}
        <ActionButton
          icon={previewStatus.completed ? 'check' : null}
          label={tc.preview}
          type="highlight"
          onClick={handlePreview}
          disabled={previewButtonDisabled}
        />
        {!previewStatus.loading &&
          orders.mailingsPdfPreview?.blob?.sizeError && (
            <Info>
              <p>
                Den PDF-fil du valt verkar inte ha rätt dimensioner eller
                format. Var god försök igen eller kontakta support.
              </p>
            </Info>
          )}
        {orders.mailingsPdfPreview?.blob &&
          !orders.mailingsPdfPreview?.blob?.sizeError &&
          previewStatus.show && (
            <PdfPreview
              setPreviewStatus={setPreviewStatus}
              selectedPdf={selectedPdf}
              orders={orders}
              onClose={() => {
                setPreviewStatus((prev) => {
                  return { ...prev, show: false };
                });
                if (!orders.mailingsPdfPreview?.blob?.sizeError) {
                  setPreviewStatus((prev) => {
                    return { ...prev, show: false, completed: false };
                  });
                }

                if (selectedPdf?.validated) {
                  setPreviewStatus((prev) => {
                    return { ...prev, completed: true, show: false };
                  });
                }
                setOrderStep(6);
              }}
            />
          )}
      </OrderStep>
      <OrderStep label={'5. Leveransinformation'}>
        <div>
          {mailingsVehicleError && mailingsSettings.mailingsType === 'car' && (
            <Info>
              <p className={styles.stepWarning}>
                {tc.mailingsVehicleLimitError}
              </p>
            </Info>
          )}
          {mailingsVehicleError && mailingsSettings.mailingsType === 'user' && (
            <Info>
              <p className={styles.stepWarning}>{tc.mailingsParameterNotice}</p>
            </Info>
          )}
          <p className={styles.stepInstruction}>{tc.deliveryTimeMailings1}</p>
          <p className={styles.stepInstruction}>{tc.deliveryTimeMailings2}</p>
        </div>
      </OrderStep>

      <>
        <OrderStep label={`6. ${tc.placeOrder}`}>
          <div>
            <p className={styles.stepInstruction}>
              När du skapat din order kommer den köras så snart det är möjligt.
              Om du vill få en notis när din beställning har behandlats av
              Bilprospekt, aktivera "Notis Brevutskick" i dina inställningar.
            </p>
          </div>
          {price.error ? (
            <Info>
              <p>Något har gått fel vid beräkning av pris för utskicket.</p>
            </Info>
          ) : (
            <PriceDisplay {...price} />
          )}
          <ActionButton
            label={tc.placeOrder}
            type="highlight"
            disabled={createOrderButtonDisabled}
            onClick={async () => {
              setIsCreating(true);
              await createMailingsOrderWithParams({
                pdfId: selectedPdfId,
                color: mailingsSettings.color,
                postage: mailingsSettings.postage,
                simplexDuplex: mailingsSettings.simplexDuplex,
                mailingsType: mailingsSettings.mailingsType,
                listId: list.id,
                orderName: !nameIsFresh,
              });
              setIsCreating(false);
            }}
          />
          {isCreating ? <Loading /> : null}
        </OrderStep>
      </>
    </div>
  );
}
const mapStateToProps = (state, props) => {
  return { orders: state.orders, pdf: state.pdf, list: props.list };
};

export default connect(mapStateToProps)(OrderMailings);
