export const fileHelper = {
  /**
   * Base64 to array buffer.
   */
  base64ToArrayBuffer: (data) => {
    const bString = window.atob(data);
    const bytes = new Uint8Array(bString.length);
    return bytes.map((byte, i) => bString.charCodeAt(i));
  },
  /**
   * Base64 to blob.
   */
  base64toBlob: (data, type) => {
    try {
      if (typeof data !== 'string') {
        throw new Error('Data is not string in base64toBlob');
      }

      let bufferArray = fileHelper.base64ToArrayBuffer(data);
      let blobStore = new Blob([bufferArray], {
        type: type ? type : 'application/pdf',
      });
      return window.URL.createObjectURL(blobStore);
    } catch (err) {
      throw err;
    }
  },
};
