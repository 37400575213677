import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserPdfs } from 'store/pdf/tasks';
import PdfArchiveManageFilesNew from 'components/pdf_archive_manage_files/archive/pdf_archive_manage_files_new';
import {
  checkIfFieldDisabled,
  getDefaultSettingsFromADR,
} from 'components/pdf_archive_manage_files/lib';
import { createMailingsPreviewNew } from 'store/orders/tasks';
import Loading from 'components/loading';
import LanguageContext from 'language-context';
import Info from 'components/info';
import OrderStep from 'components/orders/orders_order/order_mailings/order_step';
import PdfPreview from 'components/orders/orders_order/order_mailings/pdf_preview';
import styles from './order_mailings_subscription.module.css';
import ActionButton from 'components/action_button';

function OrderMailingsSubscription({
  orders,
  pdf,
  mailingsVehicleError,
  mailingsSettings,
  setMailingsSettings,
  orderStep,
  setOrderStep,
  selectedPdfId,
  setSelectedPdfId,
  previewStatus,
  setPreviewStatus,
}) {
  const tc = useContext(LanguageContext);

  const selectedPdf = pdf?.userPdfs?.find((pdf) => pdf.id === selectedPdfId);
  const previewButtonDisabled = orderStep < 4 || previewStatus.loading;
  useEffect(() => {
    getUserPdfs({ type: 'mailings' });
  }, []);

  async function handlePreview() {
    if (previewStatus.loading) return;
    setPreviewStatus((prev) => {
      return { ...prev, loading: true };
    });
    await createMailingsPreviewNew({
      pdfId: selectedPdfId,
      postage: mailingsSettings.postage,
      simplexDuplex: mailingsSettings.simplexDuplex,
      type: mailingsSettings.Typ,
      ADR: mailingsSettings.ADR,
      color: mailingsSettings.color,
    });
    setPreviewStatus((prev) => {
      return { ...prev, loading: false, show: true, completed: false };
    });
  }

  return (
    <div className={styles.container}>
      <OrderStep label={`1. ${tc.selectFile}`}>
        <p className={styles.stepInstruction}>{tc.mailingsInfoFileSelect}</p>
        <PdfArchiveManageFilesNew
          onSelect={(id) => {
            if (selectedPdfId === id) return;
            setSelectedPdfId(id);
            setOrderStep((step) => {
              if (step > 2) return step;
              else return 2;
            });
            setMailingsSettings((prev) => {
              const currentPdf = pdf.userPdfs.find((pdf) => pdf.id === id);
              return {
                ...prev,
                Typ: currentPdf.Typ,
                ADR: currentPdf.ADR,
                ...getDefaultSettingsFromADR(
                  pdf.userPdfs.find((pdf) => pdf.id === id).ADR
                ),
              };
            });
            setPreviewStatus((prev) => {
              return { ...prev, completed: false };
            });
          }}
          selectedIdForMailing={selectedPdfId}
        />
      </OrderStep>

      <OrderStep label={`2. ${tc.chooseMailingsMode}`}>
        <p className={styles.stepInstruction}>
          {tc.carMailingsTypeExplanation}
        </p>
        <label className={styles.radioSelector}>
          <input
            className="pdf-selector-mailings"
            name="user"
            type="radio"
            value={'user'}
            disabled={orderStep < 2}
            checked={mailingsSettings.mailingsType === 'user'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, mailingsType: 'user' };
                });
              }
              if (orderStep === 2) {
                setOrderStep(4);
              }
            }}
          />
          {tc.everyUser}
        </label>
        <label className={styles.radioSelector}>
          <input
            className="pdf-selector-mailings"
            name="car"
            type="radio"
            value={'car'}
            disabled={orderStep < 2}
            checked={mailingsSettings.mailingsType === 'car'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, mailingsType: 'car' };
                });
              }

              if (orderStep === 2) {
                setOrderStep(4);
              }
            }}
          />
          {tc.everyVehicle}
        </label>
      </OrderStep>

      <OrderStep label={`3. ${tc.customizeOrder}`}>
        <p className={styles.stepInstruction}>
          {tc.mailingsInfoCustomizeOrder}
        </p>
        <label
          htmlFor="postage-mailings-A"
          className={[styles.radioSelector]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          {tc.postageA}
          <input
            className="pdf-selector-mailings"
            name="postageA"
            id="postage-mailings-A"
            type="radio"
            value={'A'}
            disabled={
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
            }
            checked={mailingsSettings.postage === 'A'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, postage: 'A' };
                });
              }
            }}
          />
        </label>

        <label
          htmlFor="postage-mailings-B"
          className={[styles.radioSelector]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          {tc.postageB}
          <input
            className="pdf-selector-mailings"
            name="postage-mailings-B"
            id="postage-mailings-B"
            type="radio"
            disabled={
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'postage')
            }
            value={'B'}
            checked={mailingsSettings.postage === 'B'}
            onChange={(e) => {
              if (e.target.checked) {
                setMailingsSettings((prev) => {
                  return { ...prev, postage: 'B' };
                });
              }
            }}
          />
        </label>
        <label
          className={[styles.checkbox]
            .concat(
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'color')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          {tc.color}
          <input
            className="pdf-selector-mailings"
            type="checkbox"
            checked={mailingsSettings.color === true}
            onChange={() => {
              setMailingsSettings((settings) => {
                return { ...settings, color: !settings.color };
              });
            }}
            disabled={
              orderStep < 3 || checkIfFieldDisabled(selectedPdf.Typ, 'color')
            }
          />
        </label>
        <label
          className={[styles.checkbox]
            .concat(
              orderStep < 3 ||
                checkIfFieldDisabled(selectedPdf.Typ, 'simplexDuplex')
                ? styles.disabled
                : ''
            )
            .join(' ')}
        >
          {tc.doubleSided}
          <input
            className="pdf-selector-mailings"
            type="checkbox"
            checked={mailingsSettings.simplexDuplex === 'D'}
            onChange={() => {
              setMailingsSettings((settings) => {
                const next = settings.simplexDuplex === 'D' ? 'S' : 'D';
                return { ...settings, simplexDuplex: next };
              });
            }}
            disabled={
              orderStep < 3 ||
              checkIfFieldDisabled(selectedPdf.Typ, 'simplexDuplex')
            }
          />
        </label>
      </OrderStep>

      <OrderStep label={`4. ${tc.checkResult}`}>
        <p className={styles.stepInstruction}>{tc.mailingsInfoPreviewHere}</p>

        {previewStatus.loading ? (
          <>
            <Loading />
            <p>{tc.mailingsInfoPrewviewLoading}</p>
          </>
        ) : null}
        <ActionButton
          icon={previewStatus.completed ? 'check' : null}
          label={tc.preview}
          type="highlight"
          onClick={handlePreview}
          disabled={previewButtonDisabled}
        />
        {!previewStatus.loading &&
          orders.mailingsPdfPreview?.blob?.sizeError && (
            <Info>
              <p>{tc.mailingsInfoPreviewError}</p>
            </Info>
          )}
        {orders.mailingsPdfPreview?.blob &&
          !orders.mailingsPdfPreview?.blob?.sizeError &&
          previewStatus.show && (
            <PdfPreview
              setPreviewStatus={setPreviewStatus}
              selectedPdf={selectedPdf}
              orders={orders}
              onClose={() => {
                setPreviewStatus((prev) => {
                  return { ...prev, show: false };
                });
                if (!orders.mailingsPdfPreview?.blob?.sizeError) {
                  setPreviewStatus((prev) => {
                    return { ...prev, show: false, completed: false };
                  });
                }

                if (selectedPdf?.validated) {
                  setPreviewStatus((prev) => {
                    return { ...prev, completed: true, show: false };
                  });
                }
                setOrderStep(6);
              }}
            />
          )}
      </OrderStep>
      <OrderStep label={`5. ${tc.mailingsInfoDelivery}`}>
        <div>
          {mailingsVehicleError && mailingsSettings.mailingsType === 'car' && (
            <Info>
              <p className={styles.stepWarning}>
                {tc.mailingsVehicleLimitError}
              </p>
            </Info>
          )}
          {mailingsVehicleError && mailingsSettings.mailingsType === 'user' && (
            <Info>
              <p className={styles.stepWarning}>{tc.mailingsParameterNotice}</p>
            </Info>
          )}
          <p className={styles.stepInstruction}>{tc.deliveryTimeMailings1}</p>
          <p className={styles.stepInstruction}>{tc.deliveryTimeMailings2}</p>
        </div>
      </OrderStep>
    </div>
  );
}
const mapStateToProps = (state, props) => {
  return { orders: state.orders, pdf: state.pdf };
};

export default connect(mapStateToProps)(OrderMailingsSubscription);
