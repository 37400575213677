import { store } from 'store';
import { idbHelper, prospectHelper, request, requestBody } from 'helpers';
import { prospectActionTypes } from './actions';
import history from 'router-history';
import { debounce } from 'debounce';
import { getUserAccess } from 'store/user/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { createWidget } from 'store/widgets/tasks';
import _ from 'lodash';
import * as text from 'text-content';
import { fetchRequest } from 'helpers/request_helper';

/**
 * This is used when we want to add an item to an array.
 * If item already exists, just set value to what payload specifies.
 *
 * @param payload.item - object
 * @param payload.keyPath - array
 * @param payload.skipUpdateCount - bool (optional)
 */
export const addItemToArray = (payload) => {
  try {
    if (!payload || !payload?.keyPath || !payload?.item) {
      return console.error('Missing params in addItemToArray', payload);
    }

    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    ); // Clone data, important

    // Find parent and either add or activate object.
    let current = prospectHelper.findObject(dataUpdated, payload.keyPath);
    let existingItem;
    if (Array.isArray(current)) {
      existingItem = current.find((num) => num.val === payload.item.val);

      if (existingItem) {
        existingItem.active = payload.item.active;
      } else {
        current.push(payload.item);
      }
    } else if (Array.isArray(current.children)) {
      existingItem = current.children.find(
        (num) => num.val === payload.item.val
      );

      if (existingItem) {
        existingItem.active = payload.item.active;
      } else {
        current.children.push(payload.item);
      }
    }

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error('Error in addItemToArray', err);
  }
};

/**
 * Add and activate multiple items to an array.
 *
 * @param payload.items - array
 * @param payload.keyPath - array
 * @param payload.skipUpdateCount - bool (optional)
 */
export const addItemsToArray = (payload) => {
  try {
    if (!payload || !payload?.keyPath || !payload?.items) {
      return console.error('Missing params in addItemsToArray', payload);
    }

    // Clone data, important.
    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    );

    // Find parent.
    let current = prospectHelper.findObject(dataUpdated, payload.keyPath);

    // Add items.
    if (Array.isArray(current)) {
      payload.items.forEach((num) => {
        num.active = true;
        if (current.find((x) => x.val === num.val)) {
          current.map((x) => {
            x.active = true;
            return x;
          });
        } else {
          current.push(num);
        }
      });
    } else if (Array.isArray(current.children)) {
      payload.items.forEach((num) => {
        num.active = true;
        if (current.children.find((x) => x.val === num.val)) {
          current.children.map((x) => {
            x.active = true;
            return x;
          });
        } else {
          current.children.push(num);
        }
      });
    }

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error('Error in addItemsToArray', err);
  }
};

/**
 * This is used when we want to create an object in a parent object.
 *
 * This function is primarily used for brand percentages.
 *
 * @param payload.key - string - Name of key for the new object (for example when working with brandPercentages, this should be brand name)
 * @param payload.keyPath - array - Key to parent object
 * @param payload.object - object - Object we want to insert
 * @param payload.skipUpdateCount - bool
 */
export const addObjectToObject = (payload) => {
  try {
    if (!payload || !payload?.keyPath || !payload?.key || !payload?.object) {
      return console.error('Missing params in addObjectToObject', payload);
    }

    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    ); // Clone data, important

    // Find parent object.
    let current = prospectHelper.findObject(dataUpdated, payload.keyPath);

    // Add new object if not exist.
    current[payload.key] = payload.object;

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    // Update number of results.
    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error('Error in addObjectToObject', err);
  }
};

/**
 * This is used when we want to add an object to the data object, while simultaneously setting it to active.
 * Parent has to be array. Item should be value object.
 * When item is added to data, then we can use updateData() to toggle it if we want.
 *
 * If item already exists in data, we remove it.
 *
 * This function is primarily for toggling results in search boxes.
 *
 * Example: companyTypes is an empty array per default. User can search company types in data from indexedDb.
 * If user select a company type, we add it to companyTypes array.
 *
 * @param payload.item - object
 * @param payload.keyPath - array
 * @param payload.skipUpdateCount - bool
 */
export const addOrRemoveItemFromArray = (payload) => {
  try {
    if (!payload || !payload?.keyPath || !payload?.item) {
      return console.error(
        'Missing params in addOrRemoveItemFromArray',
        payload
      );
    }

    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    ); // Clone data, important

    // Find object.
    let current = prospectHelper.findObject(dataUpdated, payload.keyPath);

    // Add or remove.
    if (current.find((num) => num.val === payload.item.val)) {
      current.forEach((num, i) => {
        if (num.val === payload.item.val) {
          current.splice(i, 1);
        }
      });
    } else {
      payload.item.active = true;
      current.push(payload.item);
    }

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error('Error in addOrRemoveItemFromArray', err);
  }
};

/**
 * Values from backend contains properties 'text', 'val' and sometimes 'children'.
 * Add remaining properties that is needed for interface Value.
 *
 * @param arr
 */
const addPropertiesForValueType = (arr) => {
  return arr.map((num) => {
    if (num.children) {
      num.children = addPropertiesForValueType(num.children);
    }

    return {
      ...num,
      active: false,
      type: 'value',
    };
  });
};

/**
 * Adjust data object based on user accesses.
 * Run this after we've collected user status.
 */
export const adjustProspectDataBasedOnAccess = () => {
  try {
    if (!store.getState().prospect?.data) {
      return;
    }

    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    );

    if (!getUserAccess().customerData) {
      delete dataUpdated.car.dealerSalesmen;
      delete dataUpdated.car.dealerSalesmenType;
    }

    return store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });
  } catch (err) {
    return console.error('Error in adjustProspectDataBasedOnAccess', err);
  }
};

/**
 * Check that we have the data we need for prospektera, otherwise load it again.
 * This function should be executed on login or on browser reload (when we loose redux state).
 *
 * skipLoadFromLocalStorage - Typically we want to update data from local storage data object,
 * but not always. For instance when recreateSearch is calling this function we want to recreate
 * a search from a list criterias object and not set data from local storage.
 *
 * @param.payload.skipLoadFromLocalStorage - bool - See above.
 * @param.payload.skipWaitForBackendData - bool - Should basically only be true when executed from resetProspectData().
 */
export const checkProspectLoadedData = async (payload) => {
  try {
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_BACKEND_DATA_IS_LOADED,
      payload: false,
    });

    if (!payload?.skipWaitForBackendData) {
      // This is used to avoid multiple calls for checkProspectLoadedData() which might be the case
      // since checkSession() is executed from component useEffect. We start to render Prospektera page
      // as soon as backendDataIsLoaded is set to true, but this flag waits for everything.
      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_LOADING,
        payload: true,
      });
    }

    if (payload?.skipWaitForBackendData) {
      // If we don't wait for backend data we let loadProspectIDBData push
      // regions data into state, because by the time is has collected data from backend,
      // the data object reset below have already been executed.
      loadProspectIDBData(payload?.skipWaitForBackendData);
    } else {
      // If we wait for backend data but skip load from local storage
      // we can also let loadProspectIDBData push regions data into state.
      await loadProspectIDBData(payload?.skipLoadFromLocalStorage);
    }

    // Set backendDataIsLoaded so component can start to render.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_BACKEND_DATA_IS_LOADED,
      payload: true,
    });

    if (payload?.skipLoadFromLocalStorage) {
      // Don't update data object from local storage,
      // probably running recreateSearch and will update data
      // after checkProspectLoadedData is done.
      await Promise.all([
        getGroups(),
        getPostortPresets(),
        getContracts(),
        getAtlasRegions(),
      ]);
    } else {
      // Update data object from local storage.
      // First reset to a clean data object.
      // This is only to adjust text values to the correct language.
      // (When we initialize the data object in reducer we dont have access to store and users lang setting.)
      const dataObjWithCorrectLang = prospectHelper.getDataObjectTyped();
      const prospectData = localStorage.getItem('bilprospekt-prospect-data'); // Retrieve this before setting clean data object, which will affect localstorage.

      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_DATA,
        payload: dataObjWithCorrectLang,
      });

      // Run these after data object is reset above.
      let promises = [
        getGroups(),
        getPostortPresets(),
        getContracts(),
        getAtlasRegions(),
      ];
      if (!payload?.skipWaitForBackendData) {
        // If we wait for backend data and reset data object above,
        // we have to push regions data into state here.
        promises = promises.concat(getLkpTreeIntoState());
      }
      await Promise.all(promises);

      // Then adjust data object from local storage.
      if (prospectData) {
        const query = JSON.parse(prospectData);
        await prospectHelper.updateDataFromQuery(query);
      }
    }

    await adjustProspectDataBasedOnAccess();

    // All of data collection is done.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_LOADING,
      payload: false,
    });

    updateCount();
  } catch (err) {
    console.error('Error in checkProspectLoadedData', err);
  }
};

/**
 * Save a new group.
 *
 * Note: The possibility exists to have groups with property 'dealerId' (instead of 'userId')
 * with a dealer id value. Which means all users on that dealer have access to that group.
 * And they cannot remove/edit these groups.
 * Users cannot create dealer level groups in our UI, we create it for them by request.
 *
 * @param payload.data - array
 * @param payload.name - string
 * @param payload.groupId - 'dealer' | 'userIds' - We will probably add new types in the future.
 */
export const createGroup = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      !Array.isArray(payload?.data) ||
      !payload?.name ||
      !payload?.groupId
    ) {
      console.error('Missing params in createGroup', payload);
      return showFlashMessage(tc.couldNotSaveGroup, 'fail');
    }

    if (!['dealer', 'userIds'].includes(payload.groupId)) {
      console.error('Invalid type in createGroup', payload.type);
      return showFlashMessage(tc.couldNotSaveGroup, 'fail');
    }

    const data = await requestBody({
      data: {
        data: payload.data,
        name: payload.name,
        type: payload.groupId,
      },
      method: 'post',
      url: '/prospect/groups/',
    });

    if (data instanceof Error) {
      console.error('Error in createGroup', data);
      return showFlashMessage(tc.couldNotSaveGroup, 'fail');
    }

    showFlashMessage(tc.groupWasSaved, 'success');
    getGroups();
  } catch (err) {
    return console.error('Error in createGroup', err);
  }
};

/**
 * Get brands for all vehicle types.
 */
const getBrands = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/search/getBrandsWithModelsByType',
    });

    if (data instanceof Error) {
      return console.error('Error in getBrands', data);
    }

    return addPropertiesForValueType(data);
  } catch (err) {
    return console.error('Error in getBrands', err);
  }
};

/**
 * Get company types.
 */
const getCompanyTypes = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/search/getMainCompanyTypes',
    });

    if (data instanceof Error) {
      return console.error('Error in getCompanyTypes', data);
    }

    return addPropertiesForValueType(data);
  } catch (err) {
    return console.error('Error in getCompanyTypes', err);
  }
};

/**
 * Get sales persons for dealer (only available for dealers that is data integration customer).
 */
const getDealerSalesmen = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/prospect/dealerSalesmen',
    });

    if (data instanceof Error) {
      return console.error('Error in getDealerSalesmen', data);
    }

    // These groups are deprecated, new solution for regions.
    if (data.region) {
      delete data.region;
    }

    return addPropertiesForValueType(data);
  } catch (err) {
    return console.error('Error in getDealerSalesmen', err);
  }
};

/**
 * Get saved groups.
 * Groups are used in prospektera UI to facilitate search on multiple values at once.
 *
 * Note:
 * We save groups in both the 'data' object as well as in 'groups' object.
 * The 'data' groups is used in Prospektera page, this is why groups exist.
 * The 'groups' groups is used in Förval page when edit/create/share groups.
 *
 * The property 'groupId' determines what type of group it is.
 *
 * The groupId value 'dealer' is saved in data property sellersGroups.
 * The groupId value 'userIds' is saved in data properties exclusiveUserIdsGroups and excludeUserIdsGroups.
 *
 * The values 'orgnr' and 'userIds' for groupId serves as the same.
 * We renamed the groupId 'orgnr' to 'userIds', so 'orgnr' is deprecated.
 */
const getGroups = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/prospect/groups',
    });

    if (data instanceof Error) {
      return console.error('Error in getGroups', data);
    }

    data = data.map((num) => {
      if (num.groupId === 'orgnr') {
        num.groupId = 'userIds';
      }

      return num;
    });

    const dataGroups = {
      userIds: data
        .filter((num) => num.groupId === 'userIds')
        .map((num) => {
          return {
            text: num.name,
            val: JSON.stringify(num.data.map((num) => num.variables)),
          };
        }),
      dealer: data
        .filter((num) => num.groupId === 'dealer')
        .map((num) => {
          return {
            text: num.name,
            val: JSON.stringify(num.data.map((num) => num.variables)),
          };
        }),
    };

    const groupsGroups = {
      userIds: data.filter((num) => num.groupId === 'userIds'),
      dealer: data.filter((num) => num.groupId === 'dealer'),
    };

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_GROUPS_USER_IDS_GROUPS,
      payload: groupsGroups.userIds,
    });
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_GROUPS_DEALER_GROUPS,
      payload: groupsGroups.dealer,
    });
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA_USER_IDS_GROUPS,
      payload: addPropertiesForValueType(dataGroups.userIds),
    });
    return store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA_SELLERS_GROUPS,
      payload: addPropertiesForValueType(dataGroups.dealer),
    });
  } catch (err) {
    return console.error('Error in getGroups', err);
  }
};

/**
 * Get leasing companies.
 */
const getLeasingOwners = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/search/getLeasingCompanies',
    });

    if (data instanceof Error) {
      return console.error('Error in getLeasingOwners', data);
    }

    return addPropertiesForValueType(data);
  } catch (err) {
    return console.error('Error in getLeasingOwners', err);
  }
};

const getContracts = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/lists/contracts',
    });

    if (data instanceof Error) {
      return console.error('Error in getContracts', data);
    }

    const mappedData = addPropertiesForValueType(data);

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_CONTRACTS,
      payload: mappedData,
    });
  } catch (err) {
    return console.error('Error in getContracts', err);
  }
};

/**
 * Get lkpTree from indexedDB
 */
const _getLkpTreeFromIDB = async () => {
  try {
    let data = await idbHelper.getStoreData({ store: 'regions' });
    if (!data) {
      return false;
    }

    if (data instanceof Error) {
      return console.error('Error querying IDB in getLkpTreeFromIDB', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in getLkpTreeFromIDB', err);
  }
};

// const getDealerSalesmenIntoState = async () => {
//   try {
//     let data = await _getDealerSalesmenFromIDB();
//     return store.dispatch({
//       type: prospectActionTypes.PROSPECT_SET_DEALER_SALESMEN,
//       payload: data,
//     });
//   } catch (err) {
//     return console.error("Error in getDealerSalesmenIntoState", err);
//   }
// };

/**
 * When we prospect, we want to work with the lkpTree in a simple way and not as a string from IDB
 */
export const getLkpTreeIntoState = async () => {
  try {
    let data = await _getLkpTreeFromIDB();
    return store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_REGIONS,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getLkpTreeIntoState', err);
  }
};
/**
 * Get all presets for user.
 *
 * Note that we previously saved criterias in meta.buttonFields.
 * As of new frontend we use meta.criterias, and buttonFields is no longer used.
 *
 * Note that in old frontend users could add fields to hide in their presets,
 * in the 'fieldsToHide' property. We no longer offer this feature, now the preset only
 * holds search criterias.
 */
export const getPresets = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/prospect/preset',
    });

    if (data instanceof Error) {
      return console.error('Error in getPresets', data);
    }

    data = data
      .filter((num) => num.meta && num.meta.criterias)
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'));

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_PRESETS,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getPresets', err);
  }
};

/**
 * Get lkp data for regions
 */
export const getRegions = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/search/getRegionLkp',
    });

    if (!Array.isArray(data) || data instanceof Error) {
      console.error('Error in getRegions', data);
      return [];
    }

    return addPropertiesForValueType(data);
  } catch (err) {
    console.error('Error in getRegions', err);
    return [];
  }
};

/**
 * Get sellers, I.E. car dealers.
 */
// const getSellers = async () => {
//   try {
//     let data = await request({
//       method: 'get',
//       url: '/search/getDealers',
//     });

//     if (data instanceof Error) {
//       return console.error('Error in getSellers', data);
//     }

//     return addPropertiesForValueType(data);
//   } catch (err) {
//     return console.error('Error in getSellers', err);
//   }
// };

export const handleFreeTextBrands = (payload, keyPath) => {
  const item = {
    text: payload.val,
    val: payload.val,
    active: true,
    type: 'value',
  };

  addOrRemoveItemFromArray({ item, keyPath });
};

/**
 * Load data from backend and store in indexedDb.
 *
 * @params pushRegionsIntoState - bool - If we want to push region data into redux state.
 */
export const loadProspectIDBData = async (pushRegionsIntoState) => {
  try {
    // Get data from backend.
    const data = await Promise.all([
      getBrands(),
      getCompanyTypes(),
      getDealerSalesmen(),
      [],
      // getLeasingOwners(),
      getRegions(),
      getAtlasRegions(),
      [],
      // getSellers(),
    ]);

    const loadedData = {
      brands: data[0],
      companyTypes: data[1],
      dealerSalesmen: data[2],
      leasingOwners: data[3],
      regions: data[4],
      sellers: data[5],
      atlasRegions: data[6],
    };

    // Save to indexedDb.
    await idbHelper.addDataToProspectStores(loadedData);

    if (pushRegionsIntoState) {
      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_REGIONS,
        payload: data[4],
      });
    }
  } catch (err) {
    return console.error('Error in loadDataToIndexedDb', err);
  }
};

/**
 * Do a prospect search (clicking the Prospektera button).
 *
 * @param payload.page - number
 * @param payload.pageSize - number
 * @param payload.sort - object
 * @param payload.type - 'cars' | 'prospects'
 */
export const prospectSearch = async (payload) => {
  try {
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_LOADING,
      payload: true,
    });

    // Check if data exists in local storage.
    const prospectDataLocalStorage = localStorage.getItem(
      'bilprospekt-prospect-data'
    );
    const prospectData = prospectDataLocalStorage
      ? JSON.parse(prospectDataLocalStorage)
      : store.getState().prospect.data;

    const query = prospectHelper.createQueryObjectFromDataObject({
      data: prospectData,
      skipRebuildToBackendObjectStructure: !!prospectDataLocalStorage, // Local storage data is already in backend query format.
    });

    query.extraFilters = {};
    query.page = payload?.page ? payload.page : 0;
    query.pageSize = payload?.pageSize ? payload.pageSize : 0;
    query.sort = payload?.sort ? payload.sort : null;

    const data = await request({
      data: query,
      method: 'post',
      url: payload?.type === 'cars' ? '/prospect/car/' : '/prospect/',
    });

    if (data instanceof Error || !data) {
      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_LOADING,
        payload: false,
      });

      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_SEARCH_RESULT,
        payload: {
          data: [],
          total: {
            total: 0,
          },
        },
      });
      return console.error('Error in prospectSearch', data);
    }

    delete data.filters;
    delete data.gotAllData;

    // Create activityMapped array to use in tableHelper.
    if (Array.isArray(data?.data) && payload?.type === 'prospects') {
      data.data.map((num) => {
        let act: any = [];

        if (Array.isArray(num.activity_and_list)) {
          num.activity_and_list.forEach((activity) => {
            if (activity.taken_by) {
              if (act.find((num) => num.taken_by === activity.taken_by)) {
                return;
              } else {
                const colleagues = store.getState().user.colleagues;
                const connections = store.getState().user.connections;
                let user = colleagues.find((x) => x.id === activity.taken_by);
                if (!user) {
                  connections.forEach((dealer) => {
                    user = dealer.users.find((x) => x.id === activity.taken_by);
                  });
                }

                activity.taken_by_name = user ? user.name : '';

                act.push(activity);
              }
            } else {
              act.push(activity);
            }
          });
        }

        num.activityMapped = act;

        return num;
      });
    }

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_LOADING,
      payload: false,
    });
    return store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_SEARCH_RESULT,
      payload: data,
    });
  } catch (err) {
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_LOADING,
      payload: false,
    });
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_SEARCH_RESULT,
      payload: {
        data: [],
        total: {
          total: 0,
        },
      },
    });
    return console.error('Error in prospectSearch', err);
  }
};

/**
 * Recreate a search from data.
 * Shall work for lists, list subscription and prospect presets.
 *
 * @param query - object
 */
export const recreateSearch = async (query) => {
  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_RECREATE_SEARCH_LOADING,
    payload: true,
  });

  if (window.location.pathname !== '/prospektera') {
    history.push('/prospektera');
  }

  // Reset data object.
  const dataObjWithCorrectLang = prospectHelper.getDataObjectTyped();
  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_DATA,
    payload: dataObjWithCorrectLang,
  });

  // All data in place?
  await checkProspectLoadedData({
    skipLoadFromLocalStorage: true,
  });

  // Update data from the search object.
  await prospectHelper.updateDataFromQuery(query);

  // Done
  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_RECREATE_SEARCH_LOADING,
    payload: false,
  });

  // and done.
  updateCount();
};

/**
 * Remove a group.
 *
 * @param payload.id - string
 */
export const removeGroup = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id) {
      return console.error('Missing param in removeGroup', payload);
    }

    const data = await requestBody({
      data: {
        id: payload.id,
      },
      method: 'delete',
      url: '/prospect/groups/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotRemoveGroup, 'fail');
      return console.error('Error in removeGroup', data);
    }

    showFlashMessage(tc.groupWasRemoved, 'success');
    getGroups();
  } catch (err) {
    return console.error('Error in removeGroup', err);
  }
};

/**
 * Remove a preset
 *
 * @param payload.id - string
 */
export const removePreset = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id) {
      return console.error('Missing param in removePreset', payload);
    }

    const data = await requestBody({
      data: {
        id: payload.id,
      },
      method: 'delete',
      url: '/prospect/preset/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotRemovePreset, 'fail');
      return console.error('Error in removePreset', data);
    }

    showFlashMessage(tc.presetWasRemoved, 'success');
    getPresets();
  } catch (err) {
    return console.error('Error in removePreset', err);
  }
};

/**
 * Set data to initial state.
 */
export const resetProspectData = async () => {
  localStorage.removeItem('bilprospekt-prospect-data');
  const dataObjWithCorrectLang = prospectHelper.getDataObjectTyped();
  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_DATA,
    payload: dataObjWithCorrectLang,
  });

  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_REGIONS_ACTIVE_PRESET,
    payload: null,
  });

  setActivePreset(null);

  updateCount(); // Will run automatically when checkProspectLoadedData is done, but run it to display result for user immediately.

  checkProspectLoadedData({
    skipLoadFromLocalStorage: true,
    skipWaitForBackendData: true,
  });
};

/**
 * Save a new preset based on current prospect search criterias.
 *
 * @param payload.name - string
 */
export const savePreset = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.name) {
      return console.error('Missing params in savePreset', payload);
    }

    const query = prospectHelper.createQueryObjectFromDataObject({
      data: store.getState().prospect.data,
    });

    const data = await request({
      data: {
        criterias: query,
        name: payload.name,
      },
      method: 'post',
      url: '/prospect/preset/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotSavePreset);
      return console.error('Error in savePreset', data);
    }

    showFlashMessage(tc.presetWasSaved, 'success');
  } catch (err) {
    return console.error('Error in savePreset', err);
  }
};

/**
 * Save a prospect search to list, either selected prospect ids or the whole result.
 *
 * @param payload.listIds - array - When saving to existing lists.
 * @param payload.listName - string - When creating a new list.
 * @param payload.prospects - array - When saving selected prospect ids.
 */
export const saveToList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || (!payload?.listIds && !payload?.listName)) {
      return console.error('Missing params in saveToList', payload);
    }

    const query = prospectHelper.createQueryObjectFromDataObject({
      data: store.getState().prospect.data,
    });

    let data;
    if (payload.listIds) {
      data = await request({
        data: {
          data: query,
          ids: payload.listIds,
          prospects: payload.prospects ? payload.prospects : null,
        },
        method: 'post',
        url: '/prospect/saveToExistingList/',
      });
    } else if (payload.listName) {
      if (payload.prospects) {
        query.extraFilters = {
          user_id: payload.prospects.map((id) => {
            return {
              value: id.toString(),
            };
          }),
        };
      }

      data = await request({
        data: {
          data: query,
          name: payload.listName,
        },
        method: 'post',
        url: '/prospect/createList',
      });
    }

    if (data instanceof Error) {
      return console.error('Error in saveToList', data);
    }

    return showFlashMessage(tc.prospectsHaveBeenSavedToList, 'success');
  } catch (err) {
    showFlashMessage(tc.couldNotSaveToList);
    return console.error('Error in saveToList', err);
  }
};

/**
 * Save a new monitor list widget.
 *
 * @param payload.name - string
 */
export const saveSupertempMonitorlist = async (payload) => {
  try {
    if (!payload || !payload?.name) {
      return console.error(
        'Missing params in saveSupertempMonitorlist',
        payload
      );
    }

    const data = {
      criterias: prospectHelper.createQueryObjectFromDataObject({
        data: store.getState().prospect.data,
      }),
    };

    return createWidget({
      active: true,
      dashboards: {
        dashboard: {
          active: true,
          minimized: false,
          position: store.getState().widgets?.data?.widgets?.length
            ? store
              .getState()
              .widgets.data.widgets.filter((num) =>
                num.dashboards.hasOwnProperty('dashboard')
              ).length
            : 0,
        },
      },
      type: 'monitorList',
      name: payload.name,
      data: data,
    });
  } catch (err) {
    return console.error('Error in saveSupertempMonitorlist', err);
  }
};

/**
 * Search list names based on query.
 *
 * @param query
 */
export const searchLists = (query) => {
  function createResultFromList(list) {
    return {
      text: list.name,
      val: list.id,
    };
  }
  try {
    const lists = store.getState().lists.lists;

    if (!lists) {
      return [];
    }

    if (!query) return lists.map(createResultFromList);

    const result = lists
      .filter((list) => {
        return list.name.toLowerCase().includes(query.toLowerCase());
      })
      .map(createResultFromList);

    return addPropertiesForValueType(result) || [];
  } catch (err) {
    console.error('Error in searchLists', err);
    return [];
  }
};

export const searchColleagues = (query) => {
  function createResultFromList(user) {
    return {
      text: user.name,
      val: user.id,
    };
  }
  try {
    const colleagues = store.getState().user.colleagues;

    if (!colleagues) {
      return [];
    }

    if (!query) return colleagues.map(createResultFromList);

    const result = colleagues
      .filter((user) => {
        return user.name.toLowerCase().includes(query.toLowerCase());
      })
      .map(createResultFromList);

    return addPropertiesForValueType(result) || [];
  } catch (err) {
    console.error('Error in searchColleagues', err);
    return [];
  }
};

/**
 * Search a indexedDb store based on query, return results.
 *
 * @param payload.query - string - Search query.
 * @param payload.store - string - Store name.
 */
export const searchStore = async (payload) => {
  return await idbHelper.searchStore(payload);
};

export const setActivePreset = async (id) => {
  return store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_ACTIVE_PRESET,
    payload: id,
  });
};

/**
 * Set an array in prospect data object to empty.
 * So only use it with fields that has an empty array as default, like "excludeSavedLists" and not "carType".
 *
 * @param payload.keyPath - array
 * @param payload.skipUpdateCount - bool (optional)
 */
export const setArrayToEmpty = (payload) => {
  try {
    if (!payload || !payload?.keyPath) {
      return console.error('Missing params in setArrayToEmpty', payload);
    }

    // Clone data, important.
    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    );

    // Find parent.
    let current = prospectHelper.findObject(dataUpdated, payload.keyPath);

    // Set to empty.
    current.splice(0);

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error('Error in setArrayToEmpty', err);
  }
};

/**
 * Update hideFields object based on active car types.
 *
 * @param payload.keyPath
 * @param payload.value
 */
export const setHideFields = (payload) => {
  let hideFields = JSON.parse(
    JSON.stringify(store.getState().prospect.hideFields)
  );
  let carType = JSON.parse(
    JSON.stringify(store.getState().prospect.data.car.carType)
  );

  carType.map((num) => {
    if (num.val === payload.keyPath[2]) {
      num.active = payload.value;
    }
    return num;
  });

  for (const prop in hideFields) {
    const type = carType.find((num) => num.val === prop);
    hideFields[prop] = {
      ...hideFields[prop],
      active: type ? type.active : false,
    };
  }

  return store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_HIDE_FIELDS,
    payload: hideFields,
  });
};

export const setProspectDataRegionFromLocalStorage = (queryObject) => {
  const flatten = function(item) {
    return [item, _.flatMapDeep(item.children, flatten)];
  };

  const result = _.flatMapDeep(queryObject, flatten);

  let interestingValues = result.reduce(
    (acc, value) => [...acc, value.val],
    []
  );
  let fixRegions = (arr) => {
    arr.forEach((item) => {
      if (interestingValues.includes(item.val)) {
        item.active = true;
        if (item.children) {
          fixRegions(item.children);
        } else return;
      }
    });
  };
  let prospectDataRegion = store.getState().prospect.data.regions.regions;
  fixRegions(prospectDataRegion);

  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_REGIONS,
    payload: prospectDataRegion,
  });
};

/**
 *
 */
export const setProspectDataRegionFromPreset = (
  presetPmap,
  presetId,
  itemActive,
  presetData
) => {
  try {
    if (!presetData || !presetId) {
      return console.error('Missing params in setProspectDataRegionFromPreset');
    }

    let clearActive = (arr) => {
      arr.forEach((item) => {
        item.active = false;
        if (item.children) clearActive(item.children);
      });
    };

    // here we need to get the original, unaltered lkp tree which is in indexedDB
    var OG = store.getState().prospect.data.regions.regions;
    clearActive(OG);

    const deepFlatten = (
      (array) =>
        (array, start = []) =>
          array.reduce((acc, curr) => {
            return Array.isArray(curr.children)
              ? deepFlatten(curr.children, [...acc, curr.val])
              : [...acc, curr.val];
          }, start)
    )();

    if (!itemActive) {
      const interestingRegions = deepFlatten(presetData);

      let fixRegions = (arr) => {
        arr.forEach((item) => {
          if (interestingRegions.includes(item.val)) {
            item.active = true;
            if (item.children) {
              fixRegions(item.children);
            }
          }
        });
      };

      fixRegions(OG);

      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_REGIONS,
        payload: OG,
      });
    } else {
      // here we can adjust the active keys because this case means we are toggling
      // if we decide to unset them, do that here
    }

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_REGIONS_ACTIVE_PRESET,
      payload: itemActive ? null : presetId,
    });

    const query = prospectHelper.createQueryObjectFromDataObject({
      data: store.getState().prospect.data,
    });
    localStorage.setItem('bilprospekt-prospect-data', JSON.stringify(query));
  } catch (err) {
    return console.error('Error in setProspectDataRegionFromPreset', err);
  }
};

/**
 * Share a group.
 *
 * @param payload.id - string
 * @param payload.users - array
 */
export const shareGroup = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id || !payload?.users) {
      return console.error('Missing params in shareGroup', payload);
    }

    const data = await request({
      data: {
        id: payload.id,
        users: payload.users,
      },
      method: 'post',
      url: '/prospect/groups/share',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotShareGroup);
      return console.error('Error in shareGroup', data);
    }

    showFlashMessage(tc.groupWasShared, 'success');
  } catch (err) {
    return console.error('Error in shareGroup', err);
  }
};

/**
 * Share a preset.
 *
 * @param payload.id - string
 * @param payload.users - array
 */
export const sharePreset = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id || !payload?.users) {
      return console.error('Missing params in sharePreset', payload);
    }

    const data = await request({
      data: {
        id: payload.id,
        users: payload.users,
      },
      method: 'post',
      url: '/prospect/preset/share',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotSharePreset);
      return console.error('Error in sharePreset', data);
    }

    showFlashMessage(tc.presetWasShared, 'success');
  } catch (err) {
    return console.error('Error in sharePreset', err);
  }
};

/**
 * Perform a quick search to update count.
 */
export const updateCountDebounced = async () => {
  try {
    if (
      store.getState().prospect?.loading ||
      store.getState().prospect?.recreateSearchLoading
    ) {
      return;
    }

    let query = prospectHelper.createQueryObjectFromDataObject({
      data: store.getState().prospect.data,
    });
    query.extraFilters = {};
    query.page = 0;
    const data = await request({
      data: query,
      method: 'post',
      url: '/prospect/numberOfResults',
    });

    if (data instanceof Error) {
      return console.error('Could not get data in updateCountDebounced', data);
    }

    return store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_COUNT,
      payload: {
        count: data.count,
        query: query,
      },
    });
  } catch (err) {
    return console.error('Error in updateCountDebounced', err);
  }
};

export const updateCount = debounce(updateCountDebounced, 700);

/**
 * Update an object in data.
 *
 * Sometimes we want to update an object directly under parent, as with 'possessionTime' under 'vehicle'.
 * Sometimes we want to update an object inside an array under parent, as with 'boughtCondition' under 'vehicle'.
 *
 * Value can be a boolean when we want to set a button, or an object with 'from' and 'to' values when updating a slider.
 *
 * @param payload.keyPath - array - Minimum of two values.
 * @param payload.skipUpdateCount - bool (optional) - If we want to skip update count.
 * @param payload.type - 'value' | 'range'
 * @param payload.value - any - For type 'range' this should be: {from: number, to: number}. For type 'value' this can be boolean, number, string.
 * @param payload.skipMinMaxCheck - bool (optional) - Should only be used with input fields basically. For range objects we use input fields, this gives them the possibility to clear the fields. Note that a check for min/max values has to be executed in input onBlur, to make sure we display values within min-max range.
 * @param payload.skipUpdateCount - bool (optional) - Skip the backend query to update count. Mostly used when users is currently typing in input fields.
 * @param payload.unique - bool (optional) - For button groups where we have two choices and we want neither or one to be selected but not both.
 */
export const updateData = async (payload) => {
  try {
    if (
      !payload ||
      !payload?.keyPath ||
      payload?.keyPath?.length < 2 ||
      (payload && !payload.hasOwnProperty('value')) ||
      !payload?.type
    ) {
      return console.error('Missing params in updateData', payload);
    }

    let dataUpdated = JSON.parse(
      JSON.stringify(store.getState().prospect.data)
    ); // Clone data, important
    let current;

    // Find object.
    current = prospectHelper.findObject(dataUpdated, payload.keyPath);

    // Set values.
    if (payload.type === 'value') {
      current.active = payload.value;
    } else if (payload.type === 'range') {
      if (payload.value.hasOwnProperty('from')) {
        current.from = payload.value.from;
      } else {
        current.from = current.from ? current.from : current.min;
      }

      if (payload.value.hasOwnProperty('to')) {
        current.to = payload.value.to;
      } else {
        current.to = current.to ? current.to : current.max;
      }

      if (!payload.skipMinMaxCheck) {
        // This is used when a user is currently typing in an input field, when input is blurred then we check for min and max.
        // First cast to numbers, we allow input values to be strings.
        current.from =
          +current.from < +current.min ? +current.min : +current.from;
        current.to = +current.to > +current.max ? +current.max : +current.to;

        if (current.from > current.to) {
          current.from = current.to;
        }

        if (current.from === current.min) {
          current.from = null;
        }

        if (current.to === current.max) {
          current.to = null;
        }
      }

      if (
        (current.from === null && current.to !== null) ||
        (current.from !== null && current.to === null)
      ) {
        // If we have one of the values we cannot send null as the other (backend can't handle it).
        if (current.from === null) {
          current.from = current.min;
        }

        if (current.to === null) {
          current.to = current.max;
        }
      }
    } else if (payload.type === 'rangeUnbounded') {
      if (payload.value.from) {
        const { from } = payload.value;
        current.from = from;
      } else {
        current.from = current.min;
      }
      if (payload.value.to) {
        const { to, from } = payload.value;
        current.to = to;
        if (from) current.from = from;
        else if (!current.from) current.from = current.min;
      } else {
        current.to = null;
      }
    }

    if (
      payload.keyPath[1] &&
      payload.keyPath[1] === 'carType' &&
      payload.keyPath.length === 3
    ) {
      // If car type was toggled, set new fields to hide...
      setHideFields(payload);
      // ...also adjust brands.
      dataUpdated = await updateDataWithBrandsByCarTypes(dataUpdated);
    }

    if (
      payload.keyPath[0] === 'regions' &&
      payload.keyPath[1] === 'regions' &&
      store.getState().prospect.regionStatus
    ) {
      store.dispatch({
        type: prospectActionTypes.PROSPECT_SET_REGIONS_ACTIVE_PRESET,
        payload: null,
      });
    }

    // Do some extra checks and fixes for data object.
    dataUpdated = await updateDataExtraAdjustments({
      ...payload,
      data: dataUpdated,
      current: current,
    });

    // Save new state.
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_DATA,
      payload: dataUpdated,
    });

    // Update number of results.
    if (!payload.skipUpdateCount) {
      updateCount();
    }
  } catch (err) {
    return console.error(
      'Error in updateData:',
      JSON.stringify(payload, null, 2),
      err
    );
  }
};

/**
 * When we update data we need some extra checks and fixes.
 * Try and use this as a collector for all the extra stuff.
 *
 * @param payload.data
 * @param payload.keyPath
 * @param payload.type
 * @param payload.value
 */
const updateDataExtraAdjustments = async (payload) => {
  try {
    let { keyPath } = payload;
    const { current, data, type, value, unique } = payload;
    let dataUpdated = data;

    let valueWasReset = false;
    if (unique) {
      const nonUniqueArray = prospectHelper.findObject(
        dataUpdated,
        keyPath.slice(0, -1)
      );
      let uniqueArray = nonUniqueArray.map((item) => {
        if (item.val === keyPath[keyPath.length - 1]) return { ...item };
        else {
          return { ...item, active: false };
        }
      });
      prospectHelper.setNestedProp(
        dataUpdated,
        uniqueArray,
        keyPath.slice(0, -1)
      );
      return dataUpdated;
    }
    if (type === 'value') {
      valueWasReset = !value;
    } else if (type === 'range') {
      valueWasReset = !value.from && !value.to;
    }

    if (valueWasReset) {
      // Reset all nested objects for current object.
      let current = prospectHelper.findObject(dataUpdated, keyPath);
      prospectHelper.resetAllValuesInObject(current);

      if (keyPath[0] === 'prospect' && keyPath[1] === 'prospectTypes') {
        // A prospectType was inactivated so reset all values in that object.
        let obj =
          keyPath[1] === 'company'
            ? dataUpdated.prospect.company
            : dataUpdated.prospect.privatePerson;
        prospectHelper.resetAllValuesInObject(obj);
      }

      if (
        keyPath[0] === 'car' &&
        keyPath[1] === 'carType' &&
        keyPath.length === 3 &&
        !data.car.carType.find((num) => num.active)
      ) {
        // No car types active anymore, so reset whole car object.
        prospectHelper.resetAllValuesInObject(dataUpdated.car);
      }

      if (
        keyPath[0] === 'car' &&
        keyPath[1] === 'changeTemp' &&
        keyPath[2] === 'superhot' &&
        keyPath.length === 3
      ) {
        // Superhot was inactivated so unset superTempPrice.
        dataUpdated.diverse.supertempPrice = {
          ...data.diverse.supertempPrice,
          from: null,
          to: null,
        };
      }

      if (
        keyPath[1] === 'leasingOwnersType' &&
        !dataUpdated.car.leasingOwnersType.find((num) => num.active)
      ) {
        // No active values in leasingOwnersType, remove all values from leasingOwners.
        dataUpdated.car.leasingOwners = [];
      }

      if (
        keyPath[1] === 'sellersType' &&
        !dataUpdated.car.sellersType.find((num) => num.active)
      ) {
        // No active values in sellersType, remove all values from sellers.
        dataUpdated.car.sellers = [];
        dataUpdated.car.sellersGroups.map((num) => {
          num.active = false;
          return num;
        });
      }
    } else {
      if (
        keyPath[0] === 'car' &&
        keyPath[1] === 'carType' &&
        keyPath.length === 3
      ) {
        // Car type was activated, inactivate all the fields that we hide for this car type.
        const fields = store.getState().prospect.hideFields[keyPath[2]].fields;
        prospectHelper.setFieldsToInactive({
          data: dataUpdated,
          fields: fields,
        });
      }

      if (keyPath[1] === 'dealerSalesmenType') {
        // When setting av value in dealerSalesmenType, the other button should be set to inactive.
        const itemThatWasNotSet = dataUpdated.car.dealerSalesmenType.find(
          (num) => num.val !== keyPath[keyPath.length - 1]
        );
        itemThatWasNotSet.active = false;
      }

      if (keyPath[1] === 'leasingOwnersType') {
        // When setting av value in leasingOwnersType, the other button should be set to inactive.
        const itemThatWasNotSet = dataUpdated.car.leasingOwnersType.find(
          (num) => num.val !== keyPath[keyPath.length - 1]
        );
        itemThatWasNotSet.active = false;
      }

      if (keyPath[1] === 'sellersType') {
        // When setting av value in sellersType, the other button should be set to inactive.
        const itemThatWasNotSet = dataUpdated.car.sellersType.find(
          (num) => num.val !== keyPath[keyPath.length - 1]
        );
        itemThatWasNotSet.active = false;
      }
    }

    return dataUpdated;
  } catch (err) {
    console.error('Error in updateDataAdjustments', err);
    return payload.data;
  }
};

/**
 * Set brands by active car types, only display brands that exists in all active car types.
 *
 * @param data - obj - Prospect data object.
 */
export const updateDataWithBrandsByCarTypes = async (data) => {
  try {
    const dataUpdated = JSON.parse(JSON.stringify(data));
    const activeCarTypes = dataUpdated.car.carType
      .filter((num) => num.active)
      .map((num) => num.val);

    if (
      !store.getState().prospect.backendDataIsLoaded ||
      !activeCarTypes ||
      !activeCarTypes.length
    ) {
      dataUpdated.car.brands = [];
      dataUpdated.car.priorBrands = [];
      return dataUpdated;
    }

    // From bulk data, retrieve objects that hold brands for each active car type.
    let carTypes: any = await idbHelper.getBrandsByTypes(activeCarTypes);

    if (
      !Array.isArray(carTypes) ||
      (Array.isArray(carTypes) && !carTypes.length)
    ) {
      // No brands, for whatever reason.
      dataUpdated.car.brands = [];
      dataUpdated.car.priorBrands = [];
      return dataUpdated;
    }

    let brandsResult: any = [];
    if (carTypes.length > 1) {
      // Only save brands that exists in other active car types.
      carTypes.forEach((type) => {
        if (Array.isArray(type?.children)) {
          type.children.forEach((brand) => {
            // Check if brand is already added to result, if so no check necessary.
            if (!brandsResult.find((num) => num.val === brand.val)) {
              let existsInAll = true;
              carTypes.forEach((type2) => {
                if (Array.isArray(type2?.children)) {
                  if (!type2.children.find((num) => num.val === brand.val)) {
                    existsInAll = false;
                  }
                }
              });

              if (existsInAll) {
                // When multiple car types is active, we hide models.
                delete brand.children;

                // If brand already exists in state we need to set 'active' to same value as before.
                const brandInState = store
                  .getState()
                  .prospect.data.car.brands.find(
                    (num) => num.val === brand.val
                  );

                brand.active = !!(brandInState && brandInState.active);

                brandsResult.push(brand);
              }
            }
          });
        }
      });
    } else if (carTypes.length === 1) {
      if (carTypes[0] && Array.isArray(carTypes[0].children)) {
        carTypes[0].children.forEach((brand) => {
          // If brand already exists in state we need to set 'active' to same value as before.
          const brandInState = store
            .getState()
            .prospect.data.car.brands.find((num) => num.val === brand.val);
          brand.active = !!(brandInState && brandInState.active);
          brandsResult.push(brand);
        });
      }
    }

    return {
      ...dataUpdated,
      car: {
        ...dataUpdated.car,
        brands: brandsResult,
        priorBrands: brandsResult,
      },
    };
  } catch (err) {
    return console.error(
      'Error in updateDataWithBrandsByCarTypes',
      err,
      'data object:',
      data
    );
  }
};

/**
 * Update a group.
 *
 * @param payload.data - array (optional)
 * @param payload.id - string
 * @param payload.name - string (optional)
 */
export const updateGroup = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id || (!payload?.data && !payload?.name)) {
      return console.error('Missing param in updateGroup', payload);
    }

    const data = await requestBody({
      data: {
        _id: payload.id,
        data: Array.isArray(payload.data) ? payload.data : null,
        name: payload.name ? payload.name : null,
      },
      method: 'put',
      url: '/prospect/groups/',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotSaveGroup, 'fail');
      return console.error('Error in updateGroup', data);
    }

    showFlashMessage(tc.groupWasSaved, 'success');
    getGroups();
  } catch (err) {
    return console.error('Error in updateGroup', err);
  }
};

export const deleteRegionPreset = async ({ _id }) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!_id) {
      return console.error('Missing param in deleteRegionPreset');
    }

    const data = await requestBody({
      data: {
        _id,
      },
      method: 'delete',
      url: '/prospect/regionPresets',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotRemoveGroup);
      return console.error('Error in removeRegionPreset', data);
    }

    showFlashMessage(tc.groupWasRemoved, 'success');
    getPostortPresets();
  } catch (err) {
    return console.error('Error in removeRegionPreset', err);
  }
};

export const shareRegionPreset = async ({ _id, share }) => {
  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    if (!_id) {
      return console.error('Missing param in shareRegionPreset');
    }

    const data = await requestBody({
      data: {
        _id,
        share,
      },
      method: 'PUT',
      url: '/prospect/regionPresets',
    });

    if (data instanceof Error) {
      showFlashMessage(tc.groupsRegionShareFail);
      return console.error('Error in shareRegionPreset', data);
    }

    showFlashMessage(tc.groupRegionShare);
    getPostortPresets();
  } catch (err) {
    return console.error('Error in removeRegionPreset', err);
  }
};

export const getPostortPresets = async () => {
  try {
    const data = await request({
      method: 'get',
      url: '/prospect/regionPresets',
    });

    if (data instanceof Error) {
      return console.error('Error in getPostortPresets', data);
    }

    // data = data  ???????? do we need any modifications? do it here
    //
    //
    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_POSTORT_PRESETS,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getPostortPresets', err);
  }
};

export const getAtlasRegions = async () => {
  try {
    const data = await request({
      method: 'get',
      url: '/prospect/getAtlasRegions',
    });

    if (data instanceof Error) {
      return console.error('Error in getAtlasRegions', data);
    }

    const payload = data?.regions?.map((area) => ({
      active: false,
      text: area.name,
      val: area.polygon,
      type: 'value',
    }));

    store.dispatch({
      type: prospectActionTypes.PROSPECT_SET_ATLAS_REGIONS,
      payload,
    });
  } catch (err) {
    return console.error('Error in getAtlasRegions', err);
  }
};

export const getUpdateDate = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/prospect/getUpdateDate',
    });

    if (data instanceof Error) {
      return console.error('Error in getUpdateDate', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in getUpdateDate', err);
  }
};

export const updateSelectedUnit = (payload) => {
  if (!payload.keyPath || !payload.selected) {
    return console.error('Missing params in updateSelectedUnit: ', payload);
  }
  const { keyPath, selected } = payload;
  const data = JSON.parse(JSON.stringify(store.getState().prospect.data));
  let current;
  for (let i = 0; i < keyPath.length; i++) {
    const key = keyPath[i];
    if (!current) {
      current = data[key];
    } else {
      current = current[key];
    }
    if (i === keyPath.length - 1) {
      current.selected = selected;
    }
  }
  store.dispatch({
    type: prospectActionTypes.PROSPECT_SET_DATA,
    payload: data,
  });
  updateCount();
};

export const saveRegionPreset = async ({ name, shared }) => {
  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;
    const state = store.getState();

    const data = (state?.prospect?.data?.regions?.regions || [])
      .filter((r) => r.active === true)
      .map((region) => ({
        ...region,
        children: region.children
          .filter((child) => child.active === true)
          .map((child) => ({
            ...child,
            children: child.children.filter(
              (subChild) => subChild.active === true
            ),
          })),
      }));

    const req = await request({
      method: 'POST',
      url: '/prospect/regionPresets',
      data: { data, name, shared },
    });

    if (req instanceof Error) {
      showFlashMessage(tc.groupsRegionCreateFail);
      throw req;
    }

    showFlashMessage(tc.groupsRegionCreate);
    getPostortPresets();
  } catch (err) {
    return console.error('Error in saveRegionPreset', err);
  }
};
