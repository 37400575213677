import { store } from 'store';
import * as text from 'text-content';
import { showFlashMessage } from 'store/flash_messages/tasks';
type ADR = 601 | 604 | 605 | 606 | 607 | 608 | 609 | 'Brev';
export const getOptions = () => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  const options = Object.freeze({
    601: {
      label: `${tc.ADR601} - ${tc.ADR601Dimensions}`,
      Typ: 'Vykort',
      ADR: 601,
    },
    604: {
      label: `${tc.ADR604} - ${tc.ADR604Dimensions}`,
      Typ: 'Vykort',
      ADR: 604,
    },
    605: {
      label: `${tc.ADR605} - ${tc.ADR605Dimensions}`,
      Typ: 'Vykort',
      ADR: 605,
    },
    606: {
      label: `${tc.ADR606} - ${tc.ADR606Dimensions}`,
      Typ: 'Vykort',
      ADR: 606,
    },
    607: {
      label: `${tc.ADR607} - ${tc.ADR607Dimensions}`,
      Typ: 'Vykort',
      ADR: 607,
    },
    608: {
      label: `${tc.ADR608} - ${tc.ADR608Dimensions}`,
      Typ: 'Vykort',
      ADR: 608,
    },
    609: {
      label: `${tc.ADR609} - ${tc.ADR609Dimensions}`,
      Typ: 'Vykort',
      ADR: 609,
    },
    Brev: { label: tc.envelope, Typ: 'Brev', ADR: 'Brev' },
  });

  return options;
};

export const getDefaultSettingsFromADR = (adr: ADR) => {
  const options = getOptions();
  const value = options[adr];
  if (!value) return {};

  if (value.Typ === 'Vykort') {
    return { postage: 'B', color: true, simplexDuplex: 'D' };
  } else if (value.Typ === 'Brev') {
    return { postage: 'A', color: true, simplexDuplex: 'D' };
  } else {
    showFlashMessage(
      'Något gick fel. Vänligen kontakta support om felet kvarstår.',
      'fail'
    );
    return null;
  }
};
export const checkIfFieldDisabled = (
  adr: ADR,
  field: 'postage' | 'color' | 'simplexDuplex'
) => {
  switch (field) {
    case 'postage':
      return adr === 'Brev' ? false : true;
    case 'color':
      return adr === 'Brev' ? false : true;
    case 'simplexDuplex':
      return adr === 'Brev' ? false : true;
    default:
      return false;
  }
};
