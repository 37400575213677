import ActionButton from 'components/action_button';
import { useContext, useState } from 'react';
import styles from './confirm_removal.module.css';
import LanguageContext from 'language-context';

/**
 *
 * @param {{label: string; onConfirm: () => Promise<void>; onCancel: () => void;}} param
 * @returns
 */
export default function ConfirmRemoval({ label, onConfirm, onCancel }) {
  const [isConfirming, setIsConfirming] = useState(false);
  const tc = useContext(LanguageContext);
  return (
    <div className={styles.container}>
      <p>{label}</p>
      <div className={styles.buttonContainer}>
        <ActionButton
          type="highlight"
          disabled={isConfirming}
          onClick={async () => {
            setIsConfirming(true);
            await onConfirm();
            setIsConfirming(false);
          }}
          label={tc.remove}
        />
        <ActionButton
          disabled={isConfirming}
          onClick={onCancel}
          label={tc.cancel}
        />
      </div>
    </div>
  );
}
