import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { prospectHelper } from 'helpers';
import { getUserAccess } from 'store/user/tasks';
import { getLists } from 'store/lists/tasks';
import { setPreview } from 'store/preview/tasks';
import {
  getPresets,
  recreateSearch,
  resetProspectData,
  setActivePreset,
  updateCount,
} from 'store/prospect/tasks';
import Loading from 'components/loading';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import ProspectHeader from './prospect_search_header';
import ProspectRow from './prospect_search_row';
import ProspectSection from './prospect_search_section';
import ProspectSubSection from './prospect_search_sub_section';

/**
 * Render prospect (Prospektera) page.
 */
const Prospect = (state) => {
  // const [sectionInScope, setSectionInScope] = useState("section-car");
  const [data, setData] = useState({});
  const tc = useContext(LanguageContext);

  useEffect(() => {
    getPresets();
    getLists({});
  }, []);

  useEffect(() => {
    updateCount();
  }, [state.prospect.regionStatus]);

  // useEffect(() => {
  //   // Set which section is in scroll scope.
  //   const _setSectionInScope = () => {
  //     const sectionCar = document.querySelector("#section-car");
  //     const sectionProspect = document.querySelector("#section-prospect");
  //     const sectionRegion = document.querySelector("#section-region");
  //     const sectionCombineWithData = document.querySelector(
  //       "#section-combine-with-data"
  //     );
  //     const prospectSearchContentBottom = document.querySelector(
  //       ".prospectSearchWrapper__prospectSearch__content__bottom__holder"
  //     );
  //
  //     if (
  //       !sectionCar ||
  //       !sectionProspect ||
  //       !sectionRegion ||
  //       !sectionCombineWithData ||
  //       !prospectSearchContentBottom
  //     ) {
  //       return;
  //     }
  //
  //     const scroll =
  //       prospectSearchContentBottom.scrollTop +
  //       prospectSearchContentBottom.offsetHeight / 6;
  //
  //     if (scroll < sectionProspect.offsetTop) {
  //       setSectionInScope("section-car");
  //     } else if (
  //       scroll >= sectionProspect.offsetTop &&
  //       scroll < sectionRegion.offsetTop
  //     ) {
  //       setSectionInScope("section-prospect");
  //     } else if (
  //       scroll >= sectionRegion.offsetTop &&
  //       scroll < sectionCombineWithData.offsetTop
  //     ) {
  //       setSectionInScope("section-region");
  //     } else if (scroll >= sectionCombineWithData.offsetTop) {
  //       setSectionInScope("section-combine-with-data");
  //     }
  //   };
  //
  //   const _findElementAndAddEventListener = (iteration) => {
  //     if (iteration > 15) {
  //       return;
  //     }
  //
  //     // Waiting for sub component to finish loading. Yes there are other ways to do this. :)
  //     setTimeout(() => {
  //       const prospectSearchContentBottom = document.querySelector(
  //         ".prospectSearchWrapper__prospectSearch__content__bottom__holder"
  //       );
  //       if (prospectSearchContentBottom) {
  //         prospectSearchContentBottom.addEventListener(
  //           "scroll",
  //           _setSectionInScope
  //         );
  //       } else {
  //         _findElementAndAddEventListener(iteration++);
  //       }
  //     }, 1000);
  //   };
  //
  //   // Add listener.
  //   _findElementAndAddEventListener(1);
  //   return () => {
  //     // Remove listener.
  //     const prospectSearchContentBottom = document.querySelector(
  //       ".prospectSearchWrapper__prospectSearch__content__bottom__holder"
  //     );
  //     if (prospectSearchContentBottom) {
  //       prospectSearchContentBottom.removeEventListener(
  //         "scroll",
  //         _setSectionInScope
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // Set data and preview data.
    setData(state.prospect.data);
    const previewData = prospectHelper.getPreviewObject(state.prospect.data);
    setPreview({
      data: previewData,
      icons: []
        .concat(previewData.car ? ['car'] : [])
        .concat(previewData.prospect ? ['prospect'] : [])
        .concat(previewData.regions ? ['regions'] : [])
        .concat(previewData.combineWithData ? ['combineWithData'] : []),
      type: 'prospectSearch',
    });
  }, [state.prospect.data, state.prospect.regionStatus]);

  /**
   * Car section.
   */
  const _renderSectionCar = () => {
    const header = { icon: 'car', text: tc.car };

    const carType = (
      <ProspectRow
        data={data.car.carType.filter(
          (num) => state.user.settings.settings.prospect.vehicleTypes[num.val]
        )}
        key={['car', 'carType'].join()}
        keyPath={['car', 'carType']}
        loading={!state.prospect.backendDataIsLoaded}
        title={tc.carType}
        type="valueArray"
      />
    );

    const freeTextBrandInput = (
      <ProspectRow
        key={['car', 'freeTextBrandInput'].join()}
        keyPath={['car', 'freeTextBrandInput']}
        title={tc.freeTextBrands}
        type="brandSearch"
      />
    );

    const freeTextModels =
      data.car.freeTextModels.length > 0 ? (
        <ProspectRow
          data={data.car.freeTextModels}
          key={['car', 'freeTextModels'].join()}
          keyPath={['car', 'freeTextModels']}
          type="valueArray"
        />
      ) : null;

    const freeTextModelInput = (
      <ProspectRow
        key={['car', 'freeTextModelInput'].join()}
        keyPath={['car', 'freeTextModelInput']}
        title={'Fritext modeller'}
        type="modelSearch"
      />
    );

    const freeTextBrands =
      data.car.freeTextBrands.length > 0 ? (
        <ProspectRow
          data={data.car.freeTextBrands}
          key={['car', 'freeTextBrands'].join()}
          keyPath={['car', 'freeTextBrands']}
          type="valueArray"
        />
      ) : null;

    const brands =
      data.car.brands && data.car.brands.length ? (
        <ProspectRow
          data={data.car.brands}
          key={['car', 'brands'].join()}
          keyPath={['car', 'brands']}
          title={tc.brands}
          type="valueArray"
        />
      ) : null;

    // (No models for ATV)
    const models =
      data.car.brands.filter((num) => num.active).length &&
      data.car.carType.filter((num) => num.active).length < 2 &&
      !data.car.carType.find((num) => num.active && num.val === 'ATV') ? (
        <ProspectRow
          dataSource={data.car.brands.filter((num) => num.active)}
          key={['car', 'brands', 'models'].join()}
          keyPath={['car', 'brands']}
          title={tc.models}
          type="models"
        />
      ) : null;

    const brandPercentages = data.car.brands.filter((num) => num.active)
      .length ? (
      <ProspectRow
        data={data.car.brandPercentages}
        dataSource={data.car.brands.filter((num) => num.active)}
        key={['car', 'brandPercentages'].join()}
        keyPath={['car', 'brandPercentages']}
        step={10} // Important
        title={tc.brandPercentages}
        type="brandPercentages"
      />
    ) : null;

    const segments = _renderSubSectionSegments();
    const carTech = _renderSubSectionCarTech();
    const carFuel = _renderSubSectionCarFuel();
    const carMisc = _renderSubSectionCarMisc();

    const content = [
      carType,
      brands,
      freeTextBrandInput,
      freeTextBrands,
      models,
      freeTextModelInput,
      freeTextModels,
      brandPercentages,
      segments,
      carMisc,
      carTech,
      carFuel,
    ];

    return (
      <ProspectSection content={content} id="section-car" header={header} />
    );
  };

  /**
   * Fields where we combine the search with data that can be viewed as "external".
   */
  const _renderSectionCombineWithData = () => {
    const header = { icon: 'combineWithData', text: tc.combineWithData };

    const exclusiveMyCustomers = (
      <ProspectRow
        data={data.combineWithData.exclusiveMyCustomers}
        key={['combineWithData', 'exclusiveMyCustomers'].join()}
        keyPath={['combineWithData', 'exclusiveMyCustomers']}
        title={tc.responsibleSalesman}
        type="search"
        loading={!state.user.colleagues}
        showExclude={true}
        excludeActive={data.combineWithData.excludeMyCustomers.active}
        isForColleagues={true}
      />
    );

    const savedLists = (
      <ProspectRow
        data={data.combineWithData.excludeSavedLists}
        key={['combineWithData', 'excludeSavedLists'].join()}
        keyPath={['combineWithData', 'excludeSavedLists']}
        loading={!state.lists.lists}
        title={tc.lists}
        type="search"
        excludeActive={data.combineWithData.excludeListsInverted.active}
        showExclude={true}
        isForLists={true}
      />
    );

    const excludeUserIdsGroups = (
      <ProspectRow
        data={data.combineWithData.excludeUserIdsGroups}
        key={['combineWithData', 'excludeUserIdsGroups'].join()}
        keyPath={['combineWithData', 'excludeUserIdsGroups']}
        loading={!state.prospect.backendDataIsLoaded}
        title={tc.excludeUserIdsGroups}
        type="valueArray"
      />
    );

    const exclusiveUserIdsGroups = (
      <ProspectRow
        data={data.combineWithData.exclusiveUserIdsGroups}
        key={['combineWithData', 'exclusiveUserIdsGroups'].join()}
        keyPath={['combineWithData', 'exclusiveUserIdsGroups']}
        loading={!state.prospect.backendDataIsLoaded}
        title={tc.exclusiveUserIdsGroups}
        type="valueArray"
      />
    );

    const exclusiveContracts = (
      <ProspectRow
        data={data.combineWithData.exclusiveContracts}
        key={['combineWithData', 'exclusiveContracts'].join()}
        keyPath={['combineWithData', 'exclusiveContracts']}
        loading={!state.prospect.backendDataIsLoaded}
        title={'Avtalslistor endast'}
        type="valueArray"
      />
    );

    const excludeContracts = (
      <ProspectRow
        data={data.combineWithData.excludeContracts}
        key={['combineWithData', 'excludeContracts'].join()}
        keyPath={['combineWithData', 'excludeContracts']}
        loading={!state.prospect.backendDataIsLoaded}
        title={'Avtalslistor exkludera'}
        type="valueArray"
      />
    );

    const content = [
      exclusiveMyCustomers,
      savedLists,
      excludeUserIdsGroups,
      exclusiveUserIdsGroups,
      exclusiveContracts,
      excludeContracts,
    ];

    return (
      <ProspectSection
        content={content}
        id="section-combine-with-data"
        header={header}
      />
    );
  };

  /**
   * Prospect section.
   */
  const _renderSectionProspect = () => {
    const header = { icon: 'user', text: tc.prospect };

    const base = ['prospect'];
    const carsOwnedKP = base.concat('carsOwned');
    const vehcileCountPBKP = base.concat('vehicleCountPB');
    const vehcileCountLBKP = base.concat('vehicleCountLB');
    const vehcileCountTLBKP = base.concat('vehicleCountTLB');
    const vehcileCountATVKP = base.concat('vehicleCountATV');
    const vehcileCountATRKP = base.concat('vehicleCountATR');
    const vehcileCountBUKP = base.concat('vehicleCountBU');
    const vehcileCountSSKP = base.concat('vehicleCountSS');
    const vehcileCountSVKP = base.concat('vehicleCountSV');
    const vehcileCountHVKP = base.concat('vehicleCountHV');
    const vehcileCountHBKP = base.concat('vehicleCountHB');
    const vehcileCountMPKP = base.concat('vehicleCountMP');
    const vehcileCountMRKP = base.concat('vehicleCountMR');
    const vehcileCountMCKP = base.concat('vehicleCountMC');
    const vehcileCountTRKP = base.concat('vehicleCountTR');

    const prospectTypes = (
      <ProspectRow
        data={data.prospect.prospectTypes}
        key={['prospect', 'prospectTypes'].join()}
        keyPath={['prospect', 'prospectTypes']}
        title={tc.prospectTypes}
        type="valueArray"
        unique
      />
    );

    const company = data.prospect.prospectTypes.find(
      (num) => num.val === 'company'
    ).active
      ? _renderSubSectionCompany()
      : null;

    const privatePerson = data.prospect.prospectTypes.find(
      (num) => num.val === 'privatePerson'
    ).active
      ? _renderSubSectionPrivatePerson()
      : null;

    const diverseTypes = (
      <ProspectRow
        data={data.prospect.operation}
        key={['prospect', 'operation'].join()}
        keyPath={['prospect', 'operation']}
        title={tc.operation}
        type="operation"
      />
    );

    const carsOwned = (
      <ProspectRow
        data={data.prospect.carsOwned}
        key={carsOwnedKP.join()}
        keyPath={carsOwnedKP}
        step={5}
        title={tc.carsOwned}
        type="rangeUnbounded"
      />
    );

    const vehicleCountPB = data.car.carType.find(
      (obj) => obj.val === 'PB' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountPB}
        key={vehcileCountPBKP.join()}
        keyPath={vehcileCountPBKP}
        step={5}
        title={tc.vehicleCountPB}
        type="range"
      />
    ) : null;

    const vehicleCountLB = data.car.carType.find(
      (obj) => obj.val === 'LB' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountLB}
        key={vehcileCountLBKP.join()}
        keyPath={vehcileCountLBKP}
        step={5}
        title={tc.vehicleCountLB}
        type="range"
      />
    ) : null;

    const vehicleCountTLB = data.car.carType.find(
      (obj) => obj.val === 'TLB' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountTLB}
        key={vehcileCountTLBKP.join()}
        keyPath={vehcileCountTLBKP}
        step={5}
        title={tc.vehicleCountTLB}
        type="range"
      />
    ) : null;

    const vehicleCountATR = data.car.carType.find(
      (obj) => obj.val === 'ATR' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountATR}
        key={vehcileCountATRKP.join()}
        keyPath={vehcileCountATRKP}
        step={5}
        title={tc.vehicleCountATR}
        type="range"
      />
    ) : null;

    const vehicleCountBU = data.car.carType.find(
      (obj) => obj.val === 'BU' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountBU}
        key={vehcileCountBUKP.join()}
        keyPath={vehcileCountBUKP}
        step={5}
        title={tc.vehicleCountBU}
        type="range"
      />
    ) : null;

    const vehicleCountATV = data.car.carType.find(
      (obj) => obj.val === 'ATV' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountATV}
        key={vehcileCountATVKP.join()}
        keyPath={vehcileCountATVKP}
        step={5}
        title={tc.vehicleCountATV}
        type="range"
      />
    ) : null;

    const vehicleCountHB = data.car.carType.find(
      (obj) => obj.val === 'HB' && obj.active === true
    ) ? (
      <ProspectRow
        key={vehcileCountHBKP.join()}
        data={data.prospect.vehicleCountHB}
        keyPath={vehcileCountHBKP}
        step={5}
        title={tc.vehicleCountHB}
        type="range"
      />
    ) : null;

    const vehicleCountHV = data.car.carType.find(
      (obj) => obj.val === 'HV' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountHV}
        key={vehcileCountHVKP.join()}
        keyPath={vehcileCountHVKP}
        step={5}
        title={tc.vehicleCountHV}
        type="range"
      />
    ) : null;

    const vehicleCountMP = data.car.carType.find(
      (obj) => obj.val === 'MP' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountMP}
        key={vehcileCountMPKP.join()}
        keyPath={vehcileCountMPKP}
        step={5}
        title={tc.vehicleCountMP}
        type="range"
      />
    ) : null;

    const vehicleCountMC = data.car.carType.find(
      (obj) => obj.val === 'MC' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountMC}
        key={vehcileCountMCKP.join()}
        keyPath={vehcileCountMCKP}
        step={5}
        title={tc.vehicleCountMC}
        type="range"
      />
    ) : null;

    const vehicleCountMR = data.car.carType.find(
      (obj) => obj.val === 'MR' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountMR}
        key={vehcileCountMRKP.join()}
        keyPath={vehcileCountMRKP}
        step={5}
        title={tc.vehicleCountMR}
        type="range"
      />
    ) : null;

    const vehicleCountSV = data.car.carType.find(
      (obj) => obj.val === 'SV' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountSV}
        key={vehcileCountSVKP.join()}
        keyPath={vehcileCountSVKP}
        step={5}
        title={tc.vehicleCountSV}
        type="range"
      />
    ) : null;

    const vehicleCountSS = data.car.carType.find(
      (obj) => obj.val === 'SS' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountSS}
        key={vehcileCountSSKP.join()}
        step={5}
        keyPath={vehcileCountSSKP}
        title={tc.vehicleCountSS}
        type="range"
      />
    ) : null;

    const vehicleCountTR = data.car.carType.find(
      (obj) => obj.val === 'TR' && obj.active === true
    ) ? (
      <ProspectRow
        data={data.prospect.vehicleCountTR}
        key={vehcileCountTRKP.join()}
        step={5}
        keyPath={vehcileCountTRKP}
        title={tc.vehicleCountTR}
        type="range"
      />
    ) : null;

    const content = [
      prospectTypes,
      diverseTypes,
      company,
      carsOwned,
      privatePerson,
      vehicleCountPB,
      vehicleCountLB,
      vehicleCountTLB,
      vehicleCountBU,
      vehicleCountHB,
      vehicleCountHV,
      vehicleCountMC,
      vehicleCountMR,
      vehicleCountMP,
      vehicleCountTR,
      vehicleCountATV,
      vehicleCountATR,
      vehicleCountSS,
      vehicleCountSV,
    ];

    return (
      <ProspectSection
        content={content}
        id="section-prospect"
        header={header}
      />
    );
  };

  /**
   * Region section.
   */
  const _renderSectionRegion = () => {
    const header = { icon: 'regions', text: tc.regions };
    const headerPresets = { icon: 'regions', text: tc.area };

    const regionPresets =
      state.prospect?.postortPresets && data.regions?.regions ? (
        <ProspectRow
          data={{
            regionData: data.regions.regions,
            postortPresets: state.prospect.postortPresets,
          }}
          key="region-presets"
          keyPath={['ignore', 'this']}
          title={tc.regionGroups}
          type="regionPreset"
        />
      ) : null;

    const län = (
      <ProspectRow
        data={data.regions.regions}
        key="län"
        keyPath={['regions', 'regions']}
        loading={!data.regions?.regions?.length}
        title={tc.county}
        type="län"
      />
    );

    const atlasRegions =
      state.prospect?.data?.atlasRegions?.atlasRegions?.length > 0 ? (
        <ProspectRow
          data={data.atlasRegions.atlasRegions}
          key={['atlasRegions', 'atlasRegions'].join()}
          keyPath={['atlasRegions', 'atlasRegions']}
          title={'Atlasområden'}
          type="valueArray"
        />
      ) : null;

    const content = [regionPresets, atlasRegions];

    return (
      <>
        <ProspectSection content={län} id="section-region" header={header} />
        <ProspectSection
          content={content}
          id="section-region"
          header={headerPresets}
        />
      </>
    );
  };

  const _renderSubSectionCarFuel = () => {
    const dontHaveFuelValues = ['ATV', 'HV', 'MC', 'SS', 'SV', 'TR'];
    const activeCarTypes = data.car.carType.filter((num) => num.active);
    let content = [];

    if (
      activeCarTypes.length &&
      activeCarTypes.find((num) => dontHaveFuelValues.includes(num.val))
    ) {
      content = [];
    } else {
      const fuel = (
        <ProspectRow
          data={data.car.fuel}
          key={['car', 'fuel'].join()}
          keyPath={['car', 'fuel']}
          title={tc.fuel}
          type="valueArray"
        />
      );

      const fuelDepletion1 = (
        <ProspectRow
          data={data.car.fuelDepletion_1}
          key={['car', 'fuelDepletion_1'].join()}
          keyPath={['car', 'fuelDepletion_1']}
          title={tc.fuelDepletion_1}
          type="range"
        />
      );

      const climateClassification = (
        <ProspectRow
          data={data.car.climateClassification}
          key={['car', 'climateClassification'].join()}
          keyPath={['car', 'climateClassification']}
          title={tc.climateClassification}
          type="valueArray"
        />
      );

      const emissionClass = (
        <ProspectRow
          data={data.car.emissionClass}
          key={['car', 'emissionClass'].join()}
          keyPath={['car', 'emissionClass']}
          title={tc.emissionClass}
          type="valueArray"
        />
      );

      const co2 = (
        <ProspectRow
          data={data.car.co2}
          key={['car', 'co2'].join()}
          keyPath={['car', 'co2']}
          title={tc.co2}
          type="range"
        />
      );

      content = [
        fuel,
        fuelDepletion1,
        climateClassification,
        emissionClass,
        co2,
      ];
    }

    return (
      <ProspectSubSection
        content={content}
        header={tc.fuelAndEmissions}
        key="carFuel"
      />
    );
  };

  const _renderSubSectionCarMisc = () => {
    // supertemp fordon ute för försäljning
    // const changeTemp = (
    //   <ProspectRow
    //     data={data.car.changeTemp}
    //     key={["car", "changeTemp"].join()}
    //     keyPath={["car", "changeTemp"]}
    //     title={tc.supertemp}
    //     type="valueArray"
    //   />
    // );

    // const supertempPrice =
    //   data.car.changeTemp &&
    //   data.car.changeTemp[0] &&
    //   data.car.changeTemp[0].active ? (
    //     <ProspectRow
    //       data={data.car.supertempPrice}
    //       key={["car", "supertempPrice"].join()}
    //       keyPath={["car", "supertempPrice"]}
    //       step={100}
    //       title={tc.supertempPrice}
    //       type="range"
    //     />
    //   ) : null;

    const sellersType = (
      <ProspectRow
        data={data.car.sellersType}
        key={['car', 'sellersType'].join()}
        keyPath={['car', 'sellersType']}
        title={tc.sellersType}
        type="valueArray"
      />
    );

    const sellers = data.car.sellersType.find((num) => num.active) ? (
      <ProspectRow
        data={data.car.sellers}
        key={['car', 'sellers'].join()}
        keyPath={['car', 'sellers']}
        loading={!state.prospect.backendDataIsLoaded}
        title={null}
        type="search"
      />
    ) : null;

    const sellersGroups = data.car.sellersType.find((num) => num.active) ? (
      <ProspectRow
        data={data.car.sellersGroups}
        key={['car', 'sellersGroups'].join()}
        keyPath={['car', 'sellersGroups']}
        loading={!state.prospect.backendDataIsLoaded}
        type="valueArray"
      />
    ) : null;

    const dealerSalesmenType = getUserAccess().customerData ? (
      <ProspectRow
        data={data.car.dealerSalesmenType}
        key={['car', 'dealerSalesmenType'].join()}
        keyPath={['car', 'dealerSalesmenType']}
        title={tc.dealerSalesmenType}
        type="valueArray"
      />
    ) : null;

    const dealerSalesmen =
      data.car.dealerSalesmenType &&
      data.car.dealerSalesmenType.find((num) => num.active) &&
      getUserAccess().customerData ? (
        <ProspectRow
          data={data.car.dealerSalesmen}
          key={['car', 'dealerSalesmen'].join()}
          keyPath={['car', 'dealerSalesmen']}
          loading={!state.prospect.backendDataIsLoaded}
          title={null}
          type="search"
        />
      ) : null;

    const leasingOwnersType = (
      <ProspectRow
        data={data.car.leasingOwnersType}
        key={['car', 'leasingOwnersType'].join()}
        keyPath={['car', 'leasingOwnersType']}
        title={tc.leasingOwnersType}
        type="valueArray"
      />
    );

    const leasingOwners = data.car.leasingOwnersType.find(
      (num) => num.active
    ) ? (
      <ProspectRow
        data={data.car.leasingOwners}
        key={['car', 'leasingOwners'].join()}
        keyPath={['car', 'leasingOwners']}
        loading={!state.prospect.backendDataIsLoaded}
        title={null}
        type="search"
      />
    ) : null;

    const finance = (
      <ProspectRow
        data={data.car.finance}
        key={['car', 'finance'].join()}
        keyPath={['car', 'finance']}
        title={tc.finance}
        type="valueArray"
      />
    );

    const boughtCondition = (
      <ProspectRow
        data={data.car.boughtCondition}
        key={['car', 'boughtCondition'].join()}
        keyPath={['car', 'boughtCondition']}
        title={tc.boughtCondition}
        type="valueArray"
        unique
      />
    );

    const vehicleImported = (
      <ProspectRow
        data={data.car.import}
        key={['car', 'import'].join()}
        keyPath={['car', 'import']}
        title={tc.import}
        type="valueArray"
        unique
      />
    );

    const inService = (
      <ProspectRow
        data={data.car.inService}
        key={['car', 'inService'].join()}
        keyPath={['car', 'inService']}
        title={tc.inService}
        type="valueArray"
        unique
      />
    );

    const inspectionCheck = data?.car?.inService.filter(
      (obj) => obj.active === true
    );

    const inspectionValidity =
      inspectionCheck.length && inspectionCheck[0].val === 'I trafik' ? (
        <ProspectRow
          data={data.car.inspectionValidity}
          key={['car', 'inspectionValidity'].join()}
          keyPath={['car', 'inspectionValidity']}
          title={tc.inspectionValidity}
          type="range"
        />
      ) : null;

    const inspectionExpiration =
      inspectionCheck.length && inspectionCheck[0].val === 'Avställd' ? (
        <ProspectRow
          data={data.car.inspectionExpiration}
          key={['car', 'inspectionExpiration'].join()}
          keyPath={['car', 'inspectionExpiration']}
          title={tc.inspectionExpiration}
          type="range"
        />
      ) : null;

    const inspectionOverdue =
      inspectionCheck.length && inspectionCheck[0].val === 'Förfallen' ? (
        <ProspectRow
          data={data.car.inspectionOverdue}
          key={['car', 'inspectionOverdue'].join()}
          keyPath={['car', 'inspectionOverdue']}
          title={tc.inspectionOverdue}
          type="range"
        />
      ) : null;

    const usage = (
      <ProspectRow
        data={data.car.usage}
        key={['car', 'usage'].join()}
        keyPath={['car', 'usage']}
        title={tc.usage}
        type="valueArray"
      />
    );

    const possessionTime = (
      <ProspectRow
        data={data.car.possessionTime}
        key={['car', 'possessionTime'].join()}
        keyPath={['car', 'possessionTime']}
        title={tc.possessionTime}
        type="range"
      />
    );

    const regDate = (
      <ProspectRow
        data={data.car.regDate}
        key={['car', 'regDate'].join()}
        keyPath={['car', 'regDate']}
        title={tc.regDate}
        type="range"
      />
    );

    const carAge = (
      <ProspectRow
        data={data.car.carAge}
        key={['car', 'carAge'].join()}
        keyPath={['car', 'carAge']}
        title={tc.carAge + ' (Produktionsmånad)'}
        type="range"
      />
    );

    const carAgeFTIT = (
      <ProspectRow
        data={data.car.carAgeFTIT}
        key={['car', 'carAgeFTIT'].join()}
        keyPath={['car', 'carAgeFTIT']}
        title={tc.carAge + ' (Första dag i trafik)'}
        type="range"
      />
    );

    const carYear = (
      <ProspectRow
        data={data.car.carYear}
        key={['car', 'carYear'].join()}
        keyPath={['car', 'carYear']}
        title={tc.carYear}
        type="range"
      />
    );

    const priorBrands = (
      <ProspectRow
        data={data.car.priorBrands}
        key={['car', 'priorBrands'].join()}
        keyPath={['car', 'priorBrands']}
        title={tc.priorBrands}
        type="valueArray"
      />
    );

    const content = [
      //changeTemp,
      //supertempPrice,
      sellersType,
      sellers,
      sellersGroups,
      dealerSalesmenType,
      dealerSalesmen,
      leasingOwnersType,
      leasingOwners,
      finance,
      boughtCondition,
      vehicleImported,
      possessionTime,
      regDate,
      carAge,
      carAgeFTIT,
      carYear,
      inService,
      inspectionValidity,
      inspectionExpiration,
      inspectionOverdue,
      usage,
      priorBrands,
    ];

    return (
      <ProspectSubSection
        content={content}
        header={tc.vehicleMisc}
        key="carMisc"
      />
    );
  };

  const _renderSubSectionCarTech = () => {
    const engineStrength = (
      <ProspectRow
        data={data.car.engineStrength}
        key={['car', 'engineStrength'].join()}
        keyPath={['car', 'engineStrength']}
        step={50}
        title={tc.engineStrength}
        type="range"
      />
    );

    const fourwheel = (
      <ProspectRow
        data={data.car.fourwheel}
        key={['car', 'fourwheel'].join()}
        keyPath={['car', 'fourwheel']}
        title={tc.fourwheel}
        type="valueArray"
      />
    );

    const height = (
      <ProspectRow
        data={data.car.height}
        key={['car', 'height'].join()}
        keyPath={['car', 'height']}
        step={100}
        title={tc.height}
        type="range"
      />
    );

    const length = (
      <ProspectRow
        data={data.car.length}
        key={['car', 'length'].join()}
        keyPath={['car', 'length']}
        step={100}
        title={tc.length}
        type="range"
      />
    );

    const width = (
      <ProspectRow
        data={data.car.width}
        key={['car', 'width'].join()}
        keyPath={['car', 'width']}
        step={100}
        title={tc.width}
        type="range"
      />
    );

    const seatsBus = (
      <ProspectRow
        data={data.car.seatsBus}
        key={['car', 'seatsBus'].join()}
        keyPath={['car', 'seatsBus']}
        title={tc.seatsBus}
        type="range"
      />
    );

    const seatsCar = (
      <ProspectRow
        data={data.car.seatsCar}
        key={['car', 'seatsCar'].join()}
        keyPath={['car', 'seatsCar']}
        title={tc.seatsCar}
        type="valueArray"
      />
    );

    const pulley = (
      <ProspectRow
        data={data.car.pulley}
        key={['car', 'pulley'].join()}
        keyPath={['car', 'pulley']}
        title={tc.pulley}
        type="valueArray"
      />
    );

    const aproxMileage = (
      <ProspectRow
        data={data.car.aproxMileage}
        key={['car', 'aproxMileage'].join()}
        keyPath={['car', 'aproxMileage']}
        title={'Uppskattat miltal (senaste besiktning)'}
        step={50}
        type="range"
        noSort={true}
      />
    );

    const weight = (
      <ProspectRow
        data={data.car.weight}
        key={['car', 'weight'].join()}
        keyPath={['car', 'weight']}
        step={50}
        title={tc.weight}
        type="range"
      />
    );

    const serviceWeight = (
      <ProspectRow
        data={data.car.service_weight}
        key={['car', 'service_weight'].join()}
        keyPath={['car', 'service_weight']}
        step={50}
        title={tc.service_weight}
        type="range"
      />
    );

    const maxLoadWeight = (
      <ProspectRow
        data={data.car.maxLoadWeight}
        key={['car', 'maxLoadWeight'].join()}
        keyPath={['car', 'maxLoadWeight']}
        step={50}
        title={tc.maxLoadWeight}
        type="range"
      />
    );

    const maxExtraWeight = (
      <ProspectRow
        data={data.car.maxExtraWeight}
        key={['car', 'maxExtraWeight'].join()}
        keyPath={['car', 'maxExtraWeight']}
        step={50}
        title={tc.maxExtraWeight}
        type="range"
      />
    );

    const submission = (
      <ProspectRow
        data={data.car.submission}
        key={['car', 'submission'].join()}
        keyPath={['car', 'submission']}
        title={tc.submission}
        type="valueArray"
      />
    );

    const duo = (
      <ProspectRow
        data={data.car.duo}
        key={['car', 'duo'].join()}
        keyPath={['car', 'duo']}
        title={tc.duo}
        type="valueArray"
      />
    );

    const coupling1 = (
      <ProspectRow
        data={data.car.coupling1}
        key={['car', 'coupling1'].join()}
        keyPath={['car', 'coupling1']}
        title={tc.coupling}
        type="valueArray"
      />
    );

    const coupling2 = (
      <ProspectRow
        data={data.car.coupling2}
        key={['car', 'coupling2'].join()}
        keyPath={['car', 'coupling2']}
        title={tc.coupling2}
        type="valueArray"
      />
    );

    const cylinderVolume = (
      <ProspectRow
        data={data.car.cylinderVolume}
        key={['car', 'cylinderVolume'].join()}
        keyPath={['car', 'cylinderVolume']}
        step={20}
        title={tc.cylinderVolume}
        type="range"
      />
    );

    const shaftAmount = (
      <ProspectRow
        data={data.car.shaftAmount}
        key={['car', 'shaftAmount'].join()}
        keyPath={['car', 'shaftAmount']}
        title={tc.shaftAmount}
        type="range"
      />
    );

    const shaftDistance_1 = (
      <ProspectRow
        data={data.car.shaftDistance_1}
        key={['car', 'shaftDistance_1'].join()}
        keyPath={['car', 'shaftDistance_1']}
        step={100}
        title={tc.shaftDistance1}
        type="range"
      />
    );

    const shaftDistance_2 = (
      <ProspectRow
        data={data.car.shaftDistance_2}
        key={['car', 'shaftDistance_2'].join()}
        keyPath={['car', 'shaftDistance_2']}
        step={100}
        title={tc.shaftDistance2}
        type="range"
      />
    );

    const shaftDistance_3 = (
      <ProspectRow
        data={data.car.shaftDistance_3}
        key={['car', 'shaftDistance_3'].join()}
        keyPath={['car', 'shaftDistance_3']}
        step={100}
        title={tc.shaftDistance3}
        type="range"
      />
    );

    const content = [
      engineStrength,
      fourwheel,
      height,
      length,
      width,
      aproxMileage,
      seatsBus,
      seatsCar,
      pulley,
      weight,
      serviceWeight,
      maxLoadWeight,
      maxExtraWeight,
      submission,
      duo,
      coupling1,
      coupling2,
      cylinderVolume,
      shaftAmount,
      shaftDistance_1,
      shaftDistance_2,
      shaftDistance_3,
    ];

    return (
      <ProspectSubSection
        content={content}
        header={tc.techSpecifications}
        key="carTech"
      />
    );
  };

  const _renderSubSectionCompany = () => {
    const base = ['prospect', 'company'];
    const sniCodesKP = base.concat('sniCodes');
    const numEmployeesKP = base.concat('numEmployees');
    const revenueKP = base.concat('revenue');
    const solidityKP = base.concat('solidity');
    const groupInsuranceKP = base.concat('groupInsurance');

    const sniCodes = (
      <ProspectRow
        data={data.prospect.company.sniCodes}
        key={sniCodesKP.join()}
        keyPath={sniCodesKP}
        loading={!state.prospect.backendDataIsLoaded}
        title={tc.sniCodes}
        type="search"
        isForSniCodes={true}
        showExclude={true}
        excludeActive={data.prospect.company.excludeSniCodes?.active}
      />
    );

    // const companyTypes = (
    //   <ProspectRow
    //     data={data.prospect.company.companyTypes}
    //     key={["prospect", "company", "companyTypes"].join()}
    //     keyPath={["prospect", "company", "companyTypes"]}
    //     loading={!state.prospect.backendDataIsLoaded}
    //     title={tc.companyTypes}
    //     type="search"
    //   />
    // );

    const numEmployees = (
      <ProspectRow
        data={data.prospect.company.numEmployees}
        key={numEmployeesKP.join()}
        keyPath={numEmployeesKP}
        title={tc.numEmployees}
        type="valueArray"
        noSort={true}
      />
    );
    const groupInsurance = (
      <ProspectRow
        data={data.prospect.company.groupInsurance}
        key={groupInsuranceKP.join()}
        keyPath={groupInsuranceKP}
        title="Har Gruppförsäkring"
        type="valueArray"
        unique
      />
    );

    const revenue = (
      <ProspectRow
        data={data.prospect.company.revenue}
        key={revenueKP.join()}
        keyPath={revenueKP}
        title={tc.revenue}
        type="range"
        step={50}
      />
    );

    const solidity = (
      <ProspectRow
        data={data.prospect.company.solidity}
        key={solidityKP.join()}
        keyPath={solidityKP}
        step={50}
        title={tc.solidity}
        type="range"
      />
    );

    const content = [
      sniCodes,
      // companyTypes,
      numEmployees,
      groupInsurance,
      revenue,
      solidity,
    ];

    return (
      <ProspectSubSection
        content={content}
        header={tc.company}
        key="company"
        minimize={false}
      />
    );
  };

  const _renderSubSectionPrivatePerson = () => {
    const base = ['prospect', 'privatePerson'];
    const genderKeyPath = base.concat('gender');
    const ageKeyPath = base.concat('age');
    const adressChangeDateKeyPath = base.concat('adressChangeDate');
    const { privatePerson } = data.prospect;

    const gender = (
      <ProspectRow
        data={privatePerson.gender}
        key={genderKeyPath.join()}
        keyPath={genderKeyPath}
        title={tc.gender}
        type="valueArray"
      />
    );

    const age = (
      <ProspectRow
        data={privatePerson.age}
        key={ageKeyPath.join()}
        keyPath={ageKeyPath}
        title={tc.age}
        type="range"
      />
    );

    const adressChangeDate = (
      <ProspectRow
        data={privatePerson.adressChangeDate}
        key={adressChangeDateKeyPath.join()}
        keyPath={adressChangeDateKeyPath}
        title={tc.adressChangeDate}
        type="range"
      />
    );

    const content = [gender, age, adressChangeDate];

    return (
      <ProspectSubSection
        content={content}
        header={tc.privatePerson}
        key="privatePerson"
        minimize={false}
      />
    );
  };

  const _renderSubSectionSegments = () => {
    const dontHaveSegmentValues = [
      'ATV',
      'BU',
      'HB',
      'HV',
      'MC',
      'MP',
      'MR',
      'SS',
      'TR',
    ];

    const activeWithSegments = data.car.carType.filter(
      (num) => num.active && !dontHaveSegmentValues.includes(num.val)
    );

    let segmentTLBActive,
      segmentTLBEuroActive,
      segmentTLBOldActive,
      segmentLBActive,
      segmentLBEuroActive,
      segmentLBOldActive,
      segment = null;

    if (activeWithSegments.length && activeWithSegments[0].val === 'TLB') {
      segmentTLBActive =
        data.car.carType
          .find((type) => type.active && type.val === 'TLB')
          .children.filter((child) => child.active).length > 0;
      segmentTLBEuroActive =
        data.car.segmentsTLBEuro.filter((child) => child.active).length > 0;
      segmentTLBOldActive =
        data.car.segmentTLBOld.length > 0 &&
        data.car.segmentTLBOld.filter((segment) => segment.active).length > 0;

      segment =
        activeWithSegments.length !== 1 ||
        segmentTLBEuroActive ||
        segmentTLBOldActive ? null : (
          <ProspectRow
            data={activeWithSegments[0].children}
            includeChildren={true}
            key={['car', 'carType', activeWithSegments[0].val].join()}
            keyPath={['car', 'carType', activeWithSegments[0].val]}
            title={activeWithSegments[0].text}
            type="valueArray"
          />
        );
    } else if (
      activeWithSegments.length &&
      activeWithSegments[0].val === 'LB'
    ) {
      segmentLBActive =
        data.car.carType
          .find((type) => type.active && type.val === 'LB')
          .children.filter((child) => child.active).length > 0;
      segmentLBEuroActive =
        data.car.segmentsLBEuro.filter((child) => child.active).length > 0;
      segmentLBOldActive =
        data.car.segmentLBOld.length > 0 &&
        data.car.segmentLBOld.filter((segment) => segment.active).length > 0;

      segment =
        activeWithSegments.length !== 1 ||
        segmentLBEuroActive ||
        segmentLBOldActive ? null : (
          <ProspectRow
            data={activeWithSegments[0].children}
            includeChildren={true}
            key={['car', 'carType', activeWithSegments[0].val].join()}
            keyPath={['car', 'carType', activeWithSegments[0].val]}
            title={activeWithSegments[0].text}
            type="valueArray"
          />
        );
    } else {
      segment =
        activeWithSegments.length !== 1 ? null : (
          <ProspectRow
            data={activeWithSegments[0].children}
            includeChildren={true}
            key={['car', 'carType', activeWithSegments[0].val].join()}
            keyPath={['car', 'carType', activeWithSegments[0].val]}
            title={activeWithSegments[0].text}
            type="valueArray"
          />
        );
    }

    const segmentsPBNew =
      segment && activeWithSegments[0].val === 'PB' ? (
        <ProspectRow
          data={data.car.segmentsPBNew}
          key={['car', 'segmentsPBNew'].join()}
          keyPath={['car', 'segmentsPBNew']}
          title={'Personbil (karrosskoder)'}
          type="valueArray"
        />
      ) : null;

    const segmentsSVEuro =
      activeWithSegments.length &&
      !data.car.segmentSVOld.filter((segment) => segment.active).length > 0 &&
      activeWithSegments[0].val === 'SV' ? (
        <ProspectRow
          data={data.car.segmentsSVEuro}
          key={['car', 'segmentsSVEuro'].join()}
          keyPath={['car', 'segmentsSVEuro']}
          title={tc.karossCodesEuro}
          type="valueArray"
          includeChildren={true}
        />
      ) : null;

    const segmentsTLBEuro =
      activeWithSegments.length &&
      activeWithSegments[0].val === 'TLB' &&
      !segmentTLBActive &&
      !segmentTLBOldActive ? (
        <ProspectRow
          data={data.car.segmentsTLBEuro}
          key={['car', 'segmentsTLBEuro'].join()}
          keyPath={['car', 'segmentsTLBEuro']}
          title={tc.karossCodesEuro}
          type="valueArray"
          includeChildren={true}
        />
      ) : null;

    const segmentsLBEuro =
      activeWithSegments.length &&
      activeWithSegments[0].val === 'LB' &&
      !segmentLBActive &&
      !segmentLBOldActive ? (
        <ProspectRow
          data={data.car.segmentsLBEuro}
          key={['car', 'segmentsLBEuro'].join()}
          keyPath={['car', 'segmentsLBEuro']}
          title={tc.karossCodesEuro}
          type="valueArray"
          includeChildren={true}
        />
      ) : null;

    const freeTextSVSegmentsOldInput =
      activeWithSegments.length &&
      !data.car.segmentsSVEuro.filter((segment) => segment.active).length > 0 &&
      activeWithSegments[0].val === 'SV' ? (
        <ProspectRow
          key={['car', 'segmentSVOldInput'].join()}
          keyPath={['car', 'segmentSVOld']}
          title={tc.karossCodesOther}
          type="karossCodeSearch"
        />
      ) : null;

    const freeTextSVSegmentsOld =
      activeWithSegments.length > 0 &&
      !data.car.segmentsSVEuro.filter((segment) => segment.active).length > 0 &&
      activeWithSegments[0].val === 'SV' ? (
        <ProspectRow
          data={data.car.segmentSVOld}
          key={['car', 'segmentSVOld'].join()}
          keyPath={['car', 'segmentSVOld']}
          type="valueArray"
        />
      ) : null;

    const freeTextTLBSegmentsOldInput =
      activeWithSegments.length && activeWithSegments[0].val === 'TLB' ? (
        <ProspectRow
          key={['car', 'segmentTLBOldInput'].join()}
          keyPath={['car', 'segmentTLBOld']}
          title={tc.karossCodesOther}
          type="karossCodeSearch"
        />
      ) : null;

    const freeTextTLBSegmentsOld =
      activeWithSegments.length > 0 &&
      activeWithSegments[0].val === 'TLB' &&
      !segmentTLBActive &&
      !segmentTLBEuroActive ? (
        <ProspectRow
          data={data.car.segmentTLBOld}
          key={['car', 'segmentTLBOld'].join()}
          keyPath={['car', 'segmentTLBOld']}
          type="valueArray"
        />
      ) : null;

    const freeTextLBSegmentsOldInput =
      activeWithSegments.length && activeWithSegments[0].val === 'LB' ? (
        <ProspectRow
          key={['car', 'segmentLBOldInput'].join()}
          keyPath={['car', 'segmentLBOld']}
          title={tc.karossCodesOther}
          type="karossCodeSearch"
        />
      ) : null;

    const freeTextLBSegmentsOld =
      activeWithSegments.length > 0 &&
      activeWithSegments[0].val === 'LB' &&
      !segmentLBActive &&
      !segmentLBEuroActive ? (
        <ProspectRow
          data={data.car.segmentLBOld}
          key={['car', 'segmentLBOld'].join()}
          keyPath={['car', 'segmentLBOld']}
          type="valueArray"
        />
      ) : null;

    let content = [];
    if (segment) {
      if (activeWithSegments[0].val !== 'PB') {
        content = content.concat(segment);
      }
    }
    if (segmentsPBNew) {
      content = content.concat([segmentsPBNew]);
    }
    if (segmentsSVEuro) {
      content = content.concat([segmentsSVEuro]);
    }
    if (freeTextSVSegmentsOldInput) {
      content = content.concat([freeTextSVSegmentsOldInput]);
    }
    if (freeTextSVSegmentsOld) {
      content = content.concat([freeTextSVSegmentsOld]);
    }
    if (segmentsTLBEuro) {
      content = content.concat([segmentsTLBEuro]);
    }
    if (freeTextTLBSegmentsOldInput) {
      content = content.concat([freeTextTLBSegmentsOldInput]);
    }
    if (freeTextTLBSegmentsOld) {
      content = content.concat([freeTextTLBSegmentsOld]);
    }
    if (freeTextLBSegmentsOld) {
      content = content.concat([freeTextLBSegmentsOld]);
    }
    if (segmentsLBEuro) {
      content = content.concat([segmentsLBEuro]);
    }
    if (freeTextLBSegmentsOldInput) {
      content = content.concat([freeTextLBSegmentsOldInput]);
    }
    if (!content.length) {
      return;
    }

    return (
      <ProspectSubSection content={content} header={tc.segment} key="segment" />
    );
  };

  const _scrollToSection = (id) => {
    const el = document.querySelector(id);

    if (!el) {
      return;
    }

    let extraScroll = 0;
    if (id !== '#section-car') {
      extraScroll = 33;
    }

    document
      .querySelector(
        '.prospectSearchWrapper__prospectSearch__content__bottom__holder'
      )
      .scroll(0, el.offsetTop + extraScroll);
  };

  const _stateCheck = () => {
    return (
      !!Object.keys(data).length &&
      !state.prospect.loading &&
      state.user?.settings?.settings
    );
  };

  return (
    <div className="prospectSearchWrapper">
      <div className="prospectSearchWrapper__prospectSearch">
        <div className="prospectSearchWrapper__prospectSearch__header">
          <div className="prospectSearchWrapper__prospectSearch__header__top">
            <PageHeader />
          </div>
          <div className="prospectSearchWrapper__prospectSearch__header__menu">
            {Array.isArray(state.prospect.presets) &&
            state.prospect.presets.length ? (
              <Menu
                items={[
                  state.prospect.backendDataIsLoaded
                    ? {
                        checkboxes: true,
                        disabled: !state.prospect.presets?.length,
                        label: tc.presets,
                        items: state.prospect.presets.map((preset) => {
                          return {
                            active: state.prospect.activePreset === preset._id,
                            label: preset.name,
                            onClick: async () => {
                              if (state.prospect.activePreset === preset._id) {
                                setActivePreset('');
                                resetProspectData();
                              } else {
                                setActivePreset(preset._id);
                                recreateSearch(preset.meta.criterias);
                              }
                            },
                          };
                        }),
                        type: 'dropdown',
                      }
                    : {
                        element: <Loading small={true} />,
                        type: 'element',
                      },
                ]}
              />
            ) : null}
          </div>
          <div className="prospectSearchWrapper__prospectSearch__header__count">
            <ProspectHeader
              count={state.prospect.count}
              onReset={() => {
                _scrollToSection('#section-car');
              }}
            />
          </div>
        </div>
        {_stateCheck() ? (
          <>
            <div className="prospectSearchWrapper__prospectSearch__content">
              <div className="prospectSearchWrapper__prospectSearch__content__top"></div>
              <div className="prospectSearchWrapper__prospectSearch__content__bottom">
                <div className="prospectSearchWrapper__prospectSearch__content__bottom__holder">
                  {_renderSectionCar()}
                  {_renderSectionProspect()}
                  {_renderSectionRegion()}
                  {_renderSectionCombineWithData()}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    lists: state.lists,
    preview: state.preview,
    prospect: state.prospect,
    user: state.user,
  };
};

export default connect(MapStateToProps)(Prospect);
