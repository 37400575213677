import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserPdfs } from 'store/pdf/tasks';
import Loading from 'components/loading';
import UploadPdfFile from '../upload_pdf_file';
import FileLibrary from '../file_library';

function PdfArchiveManageFiles({
  pdfs,
  orders,
  currentUserId,
  onSelect,
  selectedIdForMailing,
  ...props
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadingChange = (bool) => {
    if (bool !== true && bool !== false) {
      console.error('Error changing uploading state.');
      return;
    }
    setIsUploading(bool);
  };

  useEffect(() => {
    setIsLoading(true);
    getUserPdfs({ type: 'mailings' }).then(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <UploadPdfFile handleUploadingChange={handleUploadingChange} />
      {isLoading ? (
        <Loading />
      ) : (
        <FileLibrary
          isUploading={isUploading}
          onSelect={onSelect}
          currentUserId={currentUserId}
          pdfs={pdfs}
          selectedIdForMailing={selectedIdForMailing}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    pdfs: state.pdf,
    orders: state.orders,
    currentUserId: state.user.info.id,
    ...props,
  };
};
export default connect(mapStateToProps)(PdfArchiveManageFiles);
