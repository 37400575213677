import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from 'language-context';
import listHelper from 'shared_helpers/list_helper';
import { connect } from 'react-redux';
import { editListSubsciption } from 'store/lists/tasks';
import ActionButton from 'components/action_button';
import Highlight from 'components/highlight';
import IconStyled from 'components/icon_styled';
import Menu from 'components/menu';
import Popup from 'components/popup';
import WidgetFooter from 'components/widget_footer';
import WidgetHeader from 'components/widget_header';
import moment from 'moment';
import OrderMailingsSubscription from 'components/create_list_subscription/order_mailings_subscription/order_mailings_subscription';

/**
 * Render a component to create list subscriptions.
 *
 * @param state.props.close - func
 * @param state.props.lists - array - Array with list objects.
 * @param state.props.mailings.color - bool (optional) - When sending in a default value for this.
 * @param state.props.mailings.postage - "A" | "D" (optional) - When sending in a default value for this.
 * @param state.props.mailings.s3Key - string (optional) - When sending in a default value for this. If this is provided, s3SignedUrl has to be provided.
 * @param state.props.mailings.s3SignedUrl - string (optional) - When sending in a default value for this. If this is provided, s3Key has to be provided.
 * @param state.props.mailings.simplexDuplex - "D" | "S" (optional) - When sending in a default value for this.
 * @param state.props.mailings.type - "envelope" | "postcard" (optional) - When sending in a default value for this.
 * @param state.props.source - string (optional) - Right now used to signal when component is used in "orders", if so adjust text information.
 * @param state.props.subscribeFlag - number (optional) - When sending in a default value for this.
 * @param state.props.subsciptionInterval - enum string: 'weekly' || 'monthly' (required) - When sending in a default value for this.
 * @param state.props.prospectLimit - number (optional) - When sending in a default value for this.
 * @param state.props.prospectPrio - enum string: 'random' || 'fleetSize' (optional) - When sending in a default value for this.
 */
const EditListSubScription = (state) => {
  const { selectedSubscription } = state.props;
  const propMailings = selectedSubscription.mailings || {};
  const [previewStatus, setPreviewStatus] = useState({
    show: false,
    loading: false,
    completed: false,
  });
  const [orderStep, setOrderStep] = useState(1);
  const [selectedPdfId, setSelectedPdfId] = useState(
    state?.pdf?.userPdfs?.find((pdf) => pdf.s3Key === propMailings.s3Key)?.id ||
      null
  );
  const selectedPdf = state.pdf.userPdfs?.find(
    (pdf) => pdf.id === selectedPdfId
  );

  const [mailingsSettings, setMailingsSettings] = useState({
    mailingsType: propMailings.mailings_type || 'user',
    Typ: null,
    ADR: null,
    postage: 'A',
    color: true,
    simplexDuplex: 'D',
  });

  const [subscribeFlag, setSubscribeFlag] = useState(0);
  const [subsciptionInterval, setSubscriptionInterval] = useState(null);
  const [limitProspects, setLimitProspects] = useState(false);
  const [prospectLimit, setProspectLimit] = useState(0);
  const [prospectPrio, setProspectPrio] = useState(null);
  const [excludeMethod, setExcludeMethod] = useState('standard');
  const [excludeExpiration, setExcludeExpiration] = useState('');
  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (selectedSubscription) {
      setSubscriptionInterval(selectedSubscription.options.interval);

      if (state.selectedSubscription.options.limitProspects) {
        setLimitProspects(true);
        setProspectLimit(
          state.selectedSubscription.options.limitProspects.prospectLimit || 0
        );
        setProspectPrio(
          state.selectedSubscription.options.limitProspects.prospectPrio || null
        );
      }

      if (state.selectedSubscription.options.excludeProspects) {
        setExcludeMethod('timelimit');
        setExcludeExpiration(
          state.selectedSubscription.options.excludeProspects
        );
      }
      const haveMailingsType = Boolean(
        selectedSubscription?.mailings?.mailings_type
      );
      setMailingsSettings((prev) => {
        return {
          ...prev,
          mailingsType:
            selectedSubscription?.mailings?.mailings_type || prev.mailingsType,
          postage: selectedSubscription?.mailings?.postage,
          color: selectedSubscription?.mailings?.color,
        };
      });

      if (state.pdf) {
        if (state.pdf.userPdfs) {
          if (
            selectedSubscription.mailings &&
            selectedSubscription.mailings.s3Key
          ) {
            const selectedPdf = state.pdf.userPdfs.find(
              (pdf) => pdf.s3Key === selectedSubscription.mailings.s3Key
            );
            if (selectedPdf) {
              setSelectedPdfId(selectedPdf.id);
              setOrderStep(haveMailingsType ? 4 : 2);
              setMailingsSettings((prev) => {
                return {
                  ...prev,
                  ADR: selectedPdf.ADR,
                  Typ: selectedPdf.Typ,
                };
              });
            }
          }
        }
      }
    }
  }, [
    selectedSubscription,
    state.pdf,
    state.selectedSubscription.options.limitProspects,
    state.selectedSubscription.options.excludeProspects,
  ]);

  useEffect(() => {
    setSubscribeFlag(selectedSubscription.subscription_flag);
  }, [selectedSubscription]);

  const _editListSubscription = async () => {
    await editListSubsciption({
      id: selectedSubscription.id,
      mailings: {
        color: mailingsSettings.color,
        postage: mailingsSettings.postage,
        simplexDuplex: mailingsSettings.simplexDuplex,
        type: mailingsSettings.Typ,
        mailingsType: mailingsSettings.mailingsType,
        s3Key: selectedPdf.s3Key,
        ADR: mailingsSettings.ADR,
      },
      subscribeFlag,
      options: {
        interval: subsciptionInterval,
        limitProspects: limitProspects ? { prospectLimit, prospectPrio } : null,
        excludeProspects:
          excludeMethod === 'timelimit' ? excludeExpiration : null,
        toggleTimelimitON:
          !state.selectedSubscription.options.excludeProspects &&
          excludeMethod === 'timelimit',
        toggleTimelimitOFF:
          (state.selectedSubscription.options.excludeProspects &&
            excludeMethod === 'standard') ||
          (state.selectedSubscription.options.excludeProspects &&
            !subscribeFlag & listHelper.subscriptionFlags.excludeProspects),
      },
    });

    setSubscribeFlag(0);
    _resetMailings();
    state.props.close();
  };

  const _renderSuboptionsLimitProspects = () => {
    return (
      <>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
            <WidgetHeader headline={tc.limitNumber} />
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseProspectPrio}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <ActionButton
                  active={prospectPrio === 'fleetSize'}
                  icon={prospectPrio === 'fleetSize' ? 'check' : null}
                  label={tc.fleetSize}
                  onClick={() =>
                    prospectPrio === 'fleetSize'
                      ? setProspectPrio(null)
                      : setProspectPrio('fleetSize')
                  }
                  type="regular"
                />
                <ActionButton
                  active={prospectPrio === 'random'}
                  icon={prospectPrio === 'random' ? 'check' : null}
                  label={tc.random}
                  onClick={() =>
                    prospectPrio === 'random'
                      ? setProspectPrio(null)
                      : setProspectPrio('random')
                  }
                  type="regular"
                />
              </div>
            </div>
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseNumberOfProspectsToGenerate}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <input
                  type="text"
                  value={prospectLimit}
                  onChange={(e) => setProspectLimit(e.target.value)}
                  placeholder={tc.chooseNumberOfPRospects}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const _renderSuboptionsExcludeProspects = () => {
    return (
      <>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section">
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
            <WidgetHeader headline={tc.excludeProspects} />
          </div>
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings">
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                  1.
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                  {tc.chooseProspectExcludeMethod}
                </div>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                <ActionButton
                  active={excludeMethod === 'standard'}
                  icon={excludeMethod === 'standard' ? 'check' : null}
                  label={tc.indefinitely}
                  onClick={() => {
                    setExcludeMethod('standard');
                    setExcludeExpiration('');
                  }}
                  type="regular"
                />
                <ActionButton
                  active={excludeMethod === 'timelimit'}
                  icon={excludeMethod === 'timelimit' ? 'check' : null}
                  label={tc.timelimit}
                  onClick={() => {
                    if (excludeMethod === 'timelimit') {
                      setExcludeMethod('standard');
                      setExcludeExpiration('');
                    } else if (excludeMethod === 'standard') {
                      setExcludeMethod('timelimit');
                    }
                  }}
                  type="regular"
                />
              </div>
            </div>
            {excludeMethod === 'timelimit' && (
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header">
                  <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__left">
                    2.
                  </div>
                  <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__header__right">
                    {tc.chooseNumberOfMonthsToExcludeProspect}
                  </div>
                </div>
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__mailings__section__content flexHolder">
                  <input
                    type="number"
                    value={excludeExpiration}
                    onChange={(e) => setExcludeExpiration(e.target.value)}
                    placeholder={tc.chooseNumberOfMonths}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const _renderSubscriptionInfo = () => {
    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__info">
        <p>{`${tc.listsSubscriptionsInfo1} ${tc.listsSubscriptionsInfo2}`}</p>
        <p>{tc.listsSubscriptionsInfo3}</p>
        {state.props.source === 'orders' ? (
          <Highlight
            text={`${tc.listsSubscriptionsInfo5} ${tc.listsSubscriptionsInfo6}`}
          />
        ) : null}
      </div>
    );
  };

  const _renderSubscriptionOptions = () => {
    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: !!(subscribeFlag & listHelper.subscriptionFlags.name),
                icon: 'person',
                label: tc.name,
                labelSub: tc.nameSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(listHelper.subscriptionFlags.name);
                },
                type: 'button',
              },
              {
                active: !!(subscribeFlag & listHelper.subscriptionFlags.phone),
                // disabled: !(subscribeFlag & listHelper.subscriptionFlags.name),
                icon: 'phone',
                label: tc.phoneNumbers,
                labelSub: tc.phoneNumbersSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(listHelper.subscriptionFlags.phone);
                },
                type: 'button',
              },
              {
                active: !!(
                  subscribeFlag & listHelper.subscriptionFlags.mailings
                ),
                // disabled: !(
                //   subscribeFlag & listHelper.subscriptionFlags.name &&
                //   subscribeFlag & listHelper.subscriptionFlags.excludeProspects
                // ),
                icon: 'mail',
                label: tc.mailings,
                labelSub: tc.mailingsSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(
                    listHelper.subscriptionFlags.mailings
                  );
                  _resetMailings();
                },
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: subsciptionInterval === 'weekly',
                icon: 'schedule',
                label: tc.weekly,
                labelSub: tc.subscribeWeekly,
                onClick: () =>
                  subsciptionInterval === 'weekly'
                    ? null
                    : setSubscriptionInterval('weekly'),
                type: 'button',
              },
              {
                active: subsciptionInterval === 'monthly',
                icon: 'schedule',
                label: tc.monthly,
                labelSub: tc.subscribeMonthly,
                onClick: () =>
                  subsciptionInterval === 'monthly'
                    ? null
                    : setSubscriptionInterval('monthly'),
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription__options">
          <Menu
            items={[
              {
                active: !!(
                  subscribeFlag & listHelper.subscriptionFlags.excludeProspects
                ),
                icon: 'excludeProspects',
                label: tc.excludeProspects,
                labelSub: tc.excludeProspectsSubscriptionInfo,
                onClick: () => {
                  _toggleSubscribeFlagValue(
                    listHelper.subscriptionFlags.excludeProspects
                  );
                },
                type: 'button',
              },
              {
                active: limitProspects,
                icon: 'contentcut',
                label: tc.limitNumber,
                labelSub: tc.limitNumberOfProspectsToGenerate,
                onClick: () => setLimitProspects(!limitProspects),
                type: 'button',
              },
            ]}
            type="large"
          />
        </div>
      </div>
    );
  };

  const _renderSummary = () => {
    const subscriptionContent = [];

    if (subscribeFlag & listHelper.subscriptionFlags.excludeProspects)
      subscriptionContent.push(tc.excludeProspects);
    if (!(subscribeFlag & listHelper.subscriptionFlags.excludeProspects))
      subscriptionContent.push(tc.excludeProspectsNot);
    if (subscribeFlag & listHelper.subscriptionFlags.name)
      subscriptionContent.push(tc.nameOrder);
    if (subscribeFlag & listHelper.subscriptionFlags.phone)
      subscriptionContent.push(tc.phoneOrder);
    if (subscribeFlag & listHelper.subscriptionFlags.mailings)
      subscriptionContent.push(tc.mailingsOrder);
    if (subscribeFlag === 0 || subscribeFlag === 1)
      subscriptionContent.push(tc.noOrder);
    if (subsciptionInterval === 'weekly') subscriptionContent.push(tc.weekly);
    if (subsciptionInterval === 'monthly') subscriptionContent.push(tc.monthly);
    if (limitProspects && prospectLimit > 0 && prospectPrio)
      subscriptionContent.push(
        `${tc.limitNumberOfProspectsToGenerate} ${prospectLimit} ${tc.aPiece}. ${tc.priority} ${tc[prospectPrio]}`
      );

    const pdfName = selectedPdf?.name;

    return (
      <div className="createListSubscriptionWrapper__createListSubscription__content__section">
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__sectionHeader">
          <WidgetHeader headline={tc.summary} />
        </div>
        <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary">
          {_subscriptionWillCostMoney() ? (
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
              {tc.listsSubscriptionsInfo4}
            </div>
          ) : null}
          <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
            <h4>{tc.subscription}</h4>
            <p>
              {subscriptionContent.map((num, i) => {
                if (i === subscriptionContent.length - 1) {
                  return <span key={i}>{num}</span>;
                } else {
                  return (
                    <span key={i}>
                      {num}
                      <span className="bullet">&#8226;</span>
                    </span>
                  );
                }
              })}
            </p>
          </div>
          {subscribeFlag & listHelper.subscriptionFlags.mailings ? (
            <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section">
              <h4>{tc.mailings}</h4>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled
                  iconVal={`${
                    selectedPdf?.validated && previewStatus.completed
                      ? 'check'
                      : 'exclamation'
                  }`}
                  class={`${
                    selectedPdf?.validated && previewStatus.completed
                      ? 'successFill'
                      : 'fail'
                  }`}
                />
                {selectedPdf?.validated && previewStatus.completed ? (
                  <>
                    <span className="marginRight">{tc.validFile}:</span>
                    <span>{pdfName}</span>
                  </>
                ) : (
                  <span>{tc.fileNotApproved}</span>
                )}
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled iconVal="check" class="successFill" />
                <span>
                  {mailingsSettings.Typ === 'Brev' ? tc.envelope : tc.postcard}
                </span>
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                {mailingsSettings.postage === 'A' ? (
                  <>
                    <IconStyled iconVal="check" class="successFill" />
                    <span>{tc.postageA}</span>
                  </>
                ) : (
                  <>
                    <IconStyled iconVal="check" class="successFill" />
                    <span>{tc.postageB}</span>
                  </>
                )}
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                <IconStyled iconVal="check" class="successFill" />
                <span>
                  {mailingsSettings.color
                    ? tc.color
                    : `${tc.not} ${tc.color.toLowerCase()}`}
                </span>
              </div>
              {mailingsSettings.type === 'envelope' ? (
                <div className="createListSubscriptionWrapper__createListSubscription__content__section__summary__section__item">
                  <IconStyled iconVal="check" class="successFill" />
                  <span>
                    {mailingsSettings.simplexDuplex === 'D'
                      ? tc.doubleSided
                      : `${tc.not} ${tc.doubleSided.toLowerCase()}`}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const _resetMailings = () => {
    setMailingsSettings({
      mailingsType: null,
      Typ: null,
      ADR: null,
      postage: 'A',
      color: true,
      simplexDuplex: 'D',
    });
    setPreviewStatus({ show: false, completed: false, loading: false });
  };

  const _subscriptionWillCostMoney = () => {
    return (
      subscribeFlag & listHelper.subscriptionFlags.name ||
      (subscribeFlag & listHelper.subscriptionFlags.phone) |
        (subscribeFlag & listHelper.subscriptionFlags.mailings)
    );
  };

  /**
   * Where we toggle the subscriptions flags.
   * Note that phone order should only be possible when name order also is selected.
   * Note that mailings order should only be possible when name order and excludeProspects also is selected.
   *
   * @param val - bit flag
   */
  const _toggleSubscribeFlagValue = (val) => {
    let flag = subscribeFlag;

    // First check if name order was deselected, if so deselect phone and mailings as well.
    if (
      val === listHelper.subscriptionFlags.name &&
      flag & listHelper.subscriptionFlags.name
    ) {
      if (flag & listHelper.subscriptionFlags.phone) {
        flag ^= listHelper.subscriptionFlags.phone;
      }
      if (flag & listHelper.subscriptionFlags.mailings) {
        flag ^= listHelper.subscriptionFlags.mailings;
      }
    }

    // Then check if excludeProspects was deselected, if so deselect mailings as well.
    if (
      val === listHelper.subscriptionFlags.excludeProspects &&
      flag & listHelper.subscriptionFlags.excludeProspects
    ) {
      if (flag & listHelper.subscriptionFlags.mailings) {
        flag ^= listHelper.subscriptionFlags.mailings;
      }
    }

    // Then toggle the new value.
    flag ^= val;

    // Check if phone is set without name & address, if missing add name order.
    if (
      flag & listHelper.subscriptionFlags.phone &&
      ~flag & listHelper.subscriptionFlags.name
    ) {
      flag ^= listHelper.subscriptionFlags.name; // Add name.
    }

    // Check if mailings is set without name & excludeProspects, if missing add it.
    if (flag & listHelper.subscriptionFlags.mailings) {
      if (~flag & listHelper.subscriptionFlags.name) {
        flag ^= listHelper.subscriptionFlags.name;
      }
      // if (~flag & listHelper.subscriptionFlags.excludeProspects) {
      //   flag ^= listHelper.subscriptionFlags.excludeProspects;
      // }
    }

    setSubscribeFlag(flag);
  };

  return (
    <Popup close={state.props.close} size="big">
      <div className="createListSubscriptionWrapper">
        <div className="createListSubscriptionWrapper__createListSubscription">
          <>
            <>
              <div className="createListSubscriptionWrapper__createListSubscription__content">
                <div className="createListSubscriptionWrapper__createListSubscription__content__section">
                  <div className="createListSubscriptionWrapper__createListSubscription__content__section__subscription">
                    {_renderSubscriptionInfo()}
                    {_renderSubscriptionOptions()}
                  </div>
                </div>
                {limitProspects && _renderSuboptionsLimitProspects()}
                {subscribeFlag & listHelper.subscriptionFlags.excludeProspects
                  ? _renderSuboptionsExcludeProspects()
                  : null}
                {subscribeFlag & listHelper.subscriptionFlags.mailings ? (
                  <OrderMailingsSubscription
                    previewStatus={previewStatus}
                    setPreviewStatus={setPreviewStatus}
                    orderStep={orderStep}
                    setOrderStep={setOrderStep}
                    mailingsSettings={mailingsSettings}
                    setMailingsSettings={setMailingsSettings}
                    selectedPdfId={selectedPdfId}
                    setSelectedPdfId={setSelectedPdfId}
                  />
                ) : null}
                {_renderSummary()}
              </div>
              <div className="createListSubscriptionWrapper__createListSubscription__footer">
                <WidgetFooter
                  buttonOneFunc={_editListSubscription}
                  buttonOneText={tc.editListSubscription}
                  buttonTwoFunc={state.props.close}
                  buttonTwoText={tc.cancel}
                  disableButtonOne={
                    (subscribeFlag & listHelper.subscriptionFlags.mailings &&
                      (!selectedPdf?.s3Key ||
                        !selectedPdf?.validated ||
                        !previewStatus.completed)) ||
                    (limitProspects && (!prospectPrio || prospectLimit < 1)) ||
                    !subsciptionInterval
                  }
                />
              </div>
            </>
          </>
        </div>
      </div>
    </Popup>
  );
};

const MapStateToProps = (state, props) => {
  return {
    pdf: state.pdf,
    props: props,
  };
};

export default connect(MapStateToProps)(EditListSubScription);
