import {
  getUserPdfs,
  removeUserPdf,
  setPdfs,
  shareUserPdf,
} from 'store/pdf/tasks';
import ConfirmRemoval from '../confirm_removal';
import WidgetFooter from 'components/widget_footer';
import ColleaguesRows from 'components/colleagues_rows';
import ItemWithButtons from 'components/item_with_buttons';
import Icon from 'components/icon';
import TooltipComponent from 'components/tooltip';
import { useContext, useState } from 'react';
import LanguageContext from 'language-context';
import styles from './file_library.module.css';

export default function FileLibrary({
  isUploading,
  pdfs,
  currentUserId,
  onSelect,
  selectedIdForMailing,
}) {
  const [shareId, setShareId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [sharePdfUsers, setSharePdfUsers] = useState([]);
  const tc = useContext(LanguageContext);
  const handleSelect = (pdfId) => {
    if (typeof onSelect !== 'function') {
      return;
    } else onSelect(pdfId);
  };
  if (isUploading) return null;
  return (
    <div className={styles.container}>
      <fieldset>
        <legend>
          {typeof onSelect === 'function'
            ? 'Välj PDF-fil för ditt brevutskick'
            : 'Dina uppladdade PDF-filer'}
        </legend>
        {pdfs?.userPdfs?.map((pdf) => {
          const isSharing = shareId === pdf.id;
          const isDeleting = deleteId === pdf.id;
          const isSelected = selectedIdForMailing === pdf.id;
          return (
            <div key={pdf.id}>
              <div
                className={styles.pdfContainer
                  .concat(pdf.validated ? '' : ` ${styles.invalid}`)
                  .concat(
                    typeof onSelect === 'function'
                      ? ` ${styles.selectable}`
                      : ''
                  )
                  .concat(isSelected ? ` ${styles.selected}` : '')}
                onClick={() => {
                  if (!pdf.validated) return;
                  handleSelect(pdf.id);
                }}
              >
                <div className={styles.inputContainer}>
                  {typeof onSelect === 'function' ? (
                    <input
                      className="pdf-selector-mailings"
                      type="radio"
                      disabled={pdf.validated !== true}
                      checked={isSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleSelect(pdf.id);
                        } else handleSelect(null);
                      }}
                    />
                  ) : null}
                  <TooltipComponent
                    tooltipContent={
                      pdf.validated ? 'Godkänd' : 'Väntar på godkännande'
                    }
                  >
                    {pdf.validated ? (
                      <Icon class="verified bigger-svg" val="verified" />
                    ) : (
                      <Icon class="alerts-warning bigger-svg" val="alerts" />
                    )}
                  </TooltipComponent>
                  <span>{pdf.name}</span>
                </div>
                <ItemWithButtons
                  buttons={[
                    {
                      icon: 'share',
                      onClick: () => {
                        setShareId(shareId === pdf.id ? null : pdf.id);
                      },
                      tooltip: shareId === pdf.id ? tc.fold : tc.shareFile,
                    },
                    {
                      icon: 'download',
                      href: pdf.s3SignedUrl,
                      onClick: null,
                      tooltip: tc.download,
                    },
                    {
                      disabled: pdf.activeSubscriptions?.length > 0,
                      icon: 'remove',
                      onClick: () => {
                        setDeleteId(pdf.id);
                      },
                      tooltip:
                        pdf.activeSubscriptions?.length > 0
                          ? tc.cannotRemovePdfWithActiveSubscription
                          : tc.remove,
                    },
                  ]}
                />
              </div>
              <div>
                {isSharing ? (
                  <>
                    <ColleaguesRows
                      onChange={(arr) => {
                        setSharePdfUsers(arr);
                      }}
                      disabledUsers={[currentUserId]}
                    />
                    <WidgetFooter
                      buttonOneFunc={async () => {
                        await shareUserPdf({
                          id: pdf.id,
                          users: sharePdfUsers.map((user) => user.id),
                        });
                        await getUserPdfs({ type: 'mailings' });
                        setShareId(null);
                        setSharePdfUsers([]);
                      }}
                      buttonOneText={tc.shareFile}
                      buttonTwoFunc={() => {
                        setShareId(null);
                      }}
                      buttonTwoText={tc.cancel}
                      disableButtonOne={!sharePdfUsers.length}
                      noBorder={true}
                    />
                  </>
                ) : null}
              </div>
              <div>
                {isDeleting ? (
                  <ConfirmRemoval
                    label={`Är du säker på att du vill ta bort ${pdf.name}?`}
                    onConfirm={async () => {
                      await removeUserPdf({
                        id: deleteId,
                        s3Key: pdfs.userPdfs.find((pdf) => pdf.id === deleteId)
                          .s3Key,
                      });
                      setPdfs(
                        pdfs.userPdfs.filter((pdf) => pdf.id !== deleteId)
                      );
                    }}
                    onCancel={() => {
                      setDeleteId(null);
                    }}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
}
